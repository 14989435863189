import { ReactNode } from 'react';

import { Button, SimpleGrid, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useGetPaymentById, useVoidPayment } from '@paytently/api';
import { ModalActions, ModalControls, SnackbarBody, Text, TextWithLoader } from '@paytently/ui';

import { getFormattedCurrency } from '@#/utils';

export function VoidModal({ context, id, innerProps }: ContextModalProps<{ paymentId: string }>) {
  const onClose = () => context.closeModal(id);

  const { data, isLoading } = useGetPaymentById(innerProps?.paymentId);

  const form = useForm({
    mode: 'controlled',
    validateInputOnChange: true,

    initialValues: {
      amount: '0',
      reason: '',
      reasonDescription: '',
    },
  });

  const voidPaymentMutation = useVoidPayment();

  const initiateVoid = async () => {
    if (!data) return;

    const notificationId = notifications.show({
      message: <SnackbarBody message="Void processing..." />,
      position: 'bottom-center',
    });

    modals.closeAll();

    try {
      await voidPaymentMutation.mutateAsync({
        id: data?.id as string,
        reference: '',
      });

      notifications.update({
        id: notificationId,
        message: <SnackbarBody message={`Void has been requested for payment ${data?.id} `} />,
      });
    } catch (error) {
      notifications.clean();

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in voiding payment',
          text: `An error occurred during voiding payment ${data?.id}. We apologise for
        the inconvenience. Please contact your customer support manager.`,
        },
      });
    }
  };

  const DataText = ({ children }: { children: ReactNode }) => {
    return (
      <TextWithLoader isLoading={isLoading}>
        <Text weight="medium">{children}</Text>
      </TextWithLoader>
    );
  };

  return (
    <>
      <Stack gap="lg" pb="lg">
        <ModalControls title="Void payment" onClose={onClose} />
        <Text>
          Check the transaction details below to ensure that it is the payment you want to void.
        </Text>
        <SimpleGrid cols={2}>
          <Text>Transaction ID</Text>
          <DataText>{data?.id}</DataText>
          <Text>Date</Text>
          <Text>
            <DataText>{data?.requested_on}</DataText>
          </Text>
          <Text>Entity</Text>
          <Text>
            <b>Entity name TBD</b>
          </Text>
          <Text>Currency</Text>
          <DataText>{data?.currency}</DataText>
          <Text>Payment amount</Text>
          <DataText>
            {data && getFormattedCurrency({ amount: data.amount, currencyCode: data.currency })}
          </DataText>
          <Text>Payer Name</Text>
          <DataText>
            {data?.customer.first_name} {data?.customer.last_name}
          </DataText>
          <Text>Card Number</Text>
          <DataText>{data?.method.card.number}</DataText>
        </SimpleGrid>
        {/* <Select
          disabled={isLoading}
          placeholder="Select a void reason (optional)"
          data={['Cancellation', 'Duplicate charges', 'Transaction error', 'Other']}
          {...form.getInputProps('reason')}
        />
        {form.getValues().reason === 'Other' && (
          <TextInput
            {...form.getInputProps('reasonDescription')}
            disabled={isLoading}
            label="Description"
            maxLength={128}
            placeholder="Please enter a void reason (optional)"
          />
        )} */}
      </Stack>
      <ModalActions>
        <Button onClick={onClose} size="md" variant="subtle" color="neutral.3">
          Cancel
        </Button>
        <Button disabled={!form.isValid()} onClick={initiateVoid} size="md">
          Void payment
        </Button>
      </ModalActions>
    </>
  );
}
