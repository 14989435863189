import { Connector } from '@paytently/types';

export const connectorConfiguration: Connector[] = [
  {
    id: 'con_4kkqedhk0qwwx7wybnm8aac2wj',
    name: 'Easytransac',
    code: 'easytransac',
    channels: [
      {
        id: 'cha_48y3kq8dr46mw9m5k8zytx07ne',
        name: 'Easytransac',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: true,
            multiple_partial_enabled: false,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
    ],
  },
  {
    id: 'con_4vc21r3x8s0yb5tc9ywys60hd1',
    name: 'Akurateco',
    code: 'akurateco',
    channels: [
      {
        id: 'cha_4pf41z2jpx7pd9c9y5a81cwdww',
        name: 'Akurateco Test',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: true,
            multiple_partial_enabled: true,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
      {
        id: 'cha_4kahfbwbtrm4gd7qtj6s0n47nk',
        name: 'Creditco',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: true,
            multiple_partial_enabled: true,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
      {
        id: 'cha_4rk5ftsr0mq1z90fevrrkm88xr',
        name: 'Dimoco',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: true,
            multiple_partial_enabled: true,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
      {
        id: 'cha_4whk9wzvtpnbd3fcw0h2h244dt',
        name: 'Unicorn',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: false,
            multiple_partial_enabled: false,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
      {
        id: 'cha_41j1kk3ck4h822kcyqqd6dwb5m',
        name: 'E-PRO',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: false,
            multiple_partial_enabled: false,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
      {
        id: 'cha_4e47spw6btx9s3kbpcpbbt9dxj',
        name: 'Checkout.com',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: true,
            partial_enabled: true,
            multiple_partial_enabled: false,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
    ],
  },
  {
    id: 'con_d42feedb51174fceb33d6b66aa',
    name: 'Finshark',
    code: 'finshark',
    channels: [
      {
        id: 'cha_a060fb7701934802b4e6171cb1',
        name: 'Finshark',
        capabilities: {
          void: {
            enabled: false,
          },
          refunds: {
            full_enabled: false,
            partial_enabled: false,
            multiple_partial_enabled: false,
          },
          capture: {
            full_enabled: true,
            partial_enabled: false,
          },
        },
      },
    ],
  },
];
