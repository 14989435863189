import classNames from 'classnames';
import { ReactNode } from 'react';

import { Box, Divider, Flex, Stack, StyleProp } from '@mantine/core';

import { useDisplayMode } from '../../theme/displayModeHelpers';

import styles from './BottomAppBar.module.css';

export function BottomAppBar({
  primaryButtons,
  secondaryButtons,
  scrolled = true,
}: {
  primaryButtons: ReactNode;
  secondaryButtons: ReactNode;
  scrolled?: boolean;
}) {
  const { isInMobileMode } = useDisplayMode();
  const defaultGroupProps = { gap: 'xs' };

  const groupProps = isInMobileMode
    ? {
        h: '64',
        px: 'md',
        py: 'xs',
        justify: 'center',
        direction: 'row-reverse' as StyleProp<any>,
      }
    : { h: '88', px: 'xxl', pt: 'md', pb: 'lg' };

  return (
    <Stack
      gap={0}
      w="100%"
      className={classNames(styles.bottomAppBar, { [styles.scrolled]: scrolled })}
    >
      {scrolled && <Divider m={0} />}
      <Flex {...defaultGroupProps} {...groupProps}>
        <Flex flex={isInMobileMode ? 1 : 0} className={styles.primaryButtons}>
          {primaryButtons}
        </Flex>
        <Box>{secondaryButtons}</Box>
      </Flex>
    </Stack>
  );
}
