import { useNavigate } from '@tanstack/react-router';

import { AppShell } from '@mantine/core';

import { useDisplayMode } from '@paytently/ui';

import TransactionDetails from '../TransactionDetails/TransactionDetails';

type TransactionQuickViewProps = {
  paymentId: string;
};

export function TransactionQuickView({ paymentId }: TransactionQuickViewProps) {
  const navigate = useNavigate();
  const { isInTabletMode, isInMobileMode } = useDisplayMode();

  if (isInTabletMode || isInMobileMode) navigate({ to: `/details/${paymentId}` });

  return (
    <AppShell.Aside>
      <TransactionDetails paymentId={paymentId} />
    </AppShell.Aside>
  );
}
