import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import { useMemo } from 'react';

import { Menu } from '@mantine/core';

import { UserListResponse, UserResponse } from '@paytently/types';

import { DataTable } from '@#/components/DataTable';

import Delete from '@material-symbols/svg-400/rounded/delete.svg?react';
import Edit from '@material-symbols/svg-400/rounded/edit.svg?react';
import LockReset from '@material-symbols/svg-400/rounded/lock_reset.svg?react';

type UserManagementTableProps = {
  data: UserListResponse;
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  isLoading: boolean;
  onEditUser: ({ userDetails }: { userDetails: UserResponse }) => void;
  onSuspendUser: ({
    entityId,
    userDetails,
  }: {
    entityId: string;
    userDetails: UserResponse;
  }) => void;
  onResetPassword: ({ userDetails }: { userDetails: UserResponse }) => void;
};

export const UserManagementTable = ({
  data,
  tableBodyRef,
  isLoading,
  onEditUser,
  onSuspendUser,
  onResetPassword,
}: UserManagementTableProps) => {
  const dataWithoutSuspendedUsers = data.filter((user) => user.is_suspended === false);

  const columns = useMemo<MRT_ColumnDef<UserResponse>[]>(
    () => [
      {
        accessorFn: (row) => `${row.first_name} ${row.last_name}`,
        id: 'User',
        header: 'User',
        enableSorting: true,
        grow: false,
        size: 256,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableSorting: false,
        grow: false,
        size: 256,
      },
      {
        accessorKey: 'created_on',
        header: 'Date Added',
      },
      {
        accessorKey: 'last_signed_in',
        header: 'Last Login',
      },
    ],
    []
  );

  const renderRowMenu = ({ row }: { row: MRT_Row<UserResponse> }) => {
    return (
      <>
        <Menu.Item
          leftSection={<Edit width={20} />}
          onClick={() => onEditUser({ userDetails: row.original })}
        >
          Edit user details
        </Menu.Item>
        <Menu.Item
          leftSection={<LockReset width={20} />}
          onClick={() => onResetPassword({ userDetails: row.original })}
        >
          Reset password
        </Menu.Item>
        <Menu.Item
          leftSection={<Delete width={20} />}
          onClick={() => onSuspendUser({ entityId: 'TODO', userDetails: row.original })}
        >
          Delete
        </Menu.Item>
      </>
    );
  };

  return (
    <DataTable<UserResponse>
      data={dataWithoutSuspendedUsers}
      columns={columns}
      isLoading={isLoading}
      tableBodyRef={tableBodyRef}
      renderRowMenu={renderRowMenu}
      searchEnabled
    />
  );
};
