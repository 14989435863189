import { useQuery } from "@tanstack/react-query";
import { getPaymentById } from "../../api/payments";
import { PaymentResponse } from "@paytently/types";

const QUERY_KEY = "payment";

export function useGetPaymentById(id: string) {
  const query = useQuery<PaymentResponse>({
    queryKey: [QUERY_KEY, id],
    queryFn: () => getPaymentById(id),
  });

  return query;
}
