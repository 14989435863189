import { useNavigate } from '@tanstack/react-router';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';

// import { Menu } from '@mantine/core';
import { PayoutSearchResponseItem } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { DataTable } from '@#/components/DataTable';

import {
  AmountDataTableCell,
  ChannelDataTableCell,
  DateDataTableCell,
  GenericDataTableCell,
  StatusDataTableCell,
} from '@#/components';

export type PayoutsDataTableItem = Pick<
  PayoutSearchResponseItem,
  'id' | 'status' | 'amount' | 'customer' | 'method' | 'route'
>;

export const PayoutsTable = ({
  data,
  tableBodyRef,
  isLoading,
}: {
  data: PayoutSearchResponseItem[];
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  isLoading: boolean;
}) => {
  const { isInMobileMode, isInTabletMode } = useDisplayMode();
  const navigate = useNavigate({ from: '/payouts' });

  const columns = useMemo<MRT_ColumnDef<PayoutsDataTableItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Payout ID',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: GenericDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: StatusDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorKey: 'requested_on',
        header: 'Date',
        enableSorting: true,
        grow: false,
        size: 256,
        Cell: DateDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        enableSorting: true,
        grow: false,
        size: 256,
        Cell: AmountDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorKey: 'route.channel',
        header: 'Channel',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: ChannelDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorFn: (row) =>
          row.customer ? `${row.customer?.first_name} ${row.customer?.last_name}` : undefined,
        header: 'Customer Name',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: GenericDataTableCell<PayoutsDataTableItem>,
      },
      {
        accessorKey: 'application_id',
        header: 'Application ID',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: GenericDataTableCell<PayoutsDataTableItem>,
      },
    ],
    [isInMobileMode]
  );

  const columnsHiddenByDefault = {
    'open-banking-country': false,
    'open-banking-account-number': false,
    'open-banking-bic': false,
    'open-banking-account-type': false,
  };

  // const onTransactionClick = (row: MRT_Row<PaymentsDataTableItem>, fullView?: boolean) => {
  //   if (isInTabletMode || isInMobileMode || fullView) {
  //     return navigate({ to: `/details/${row.original.id}` });
  //   }

  //   return navigate({ to: `/payments/${row.original.id}` });
  // };

  // const renderRowMenu = ({ row }: { row: MRT_Row<PaymentsDataTableItem> }) => {
  //   return (
  //     <>
  //       <Menu.Item
  //         onClick={() => onTransactionClick(row, true)}
  //         leftSection={<QuickReferenceAll width={20} />}
  //       >
  //         View details
  //       </Menu.Item>
  //       <RequiresPermissions checkPermission={Permissions['void:request']}>
  //         <DisplayWithPaymentStatus
  //           paymentStatus={row.original.status}
  //           withStatuses={[Status.authorized]}
  //         >
  //           <Menu.Item
  //             leftSection={<CreditCardOff width={20} />}
  //             onClick={() =>
  //               openMobileOrDesktopModal(isInMobileMode, {
  //                 modal: 'void',
  //                 size: '512px',
  //                 innerProps: {
  //                   paymentId: row.original.id,
  //                 },
  //               })
  //             }
  //           >
  //             Void
  //           </Menu.Item>
  //         </DisplayWithPaymentStatus>
  //       </RequiresPermissions>
  //       <RequiresPermissions checkPermission={Permissions['refund:request']}>
  //         <DisplayWithPaymentStatus
  //           paymentStatus={row.original.status}
  //           withStatuses={[Status.captured]}
  //         >
  //           <Menu.Item
  //             leftSection={<CreditCardOff width={20} />}
  //             disabled={row.original.amount_details.available_to_refund <= 0}
  //             onClick={() =>
  //               openMobileOrDesktopModal(isInMobileMode, {
  //                 modal: 'refund',
  //                 size: '512px',
  //                 innerProps: {
  //                   paymentId: row.original.id,
  //                 },
  //               })
  //             }
  //           >
  //             Refund
  //           </Menu.Item>
  //         </DisplayWithPaymentStatus>
  //       </RequiresPermissions>
  //     </>
  //   );
  // };

  return (
    <DataTable<any>
      data={data}
      columns={columns}
      isLoading={isLoading}
      onRowClick={() => alert('Payout clicked')}
      tableBodyRef={tableBodyRef}
      renderRowMenu={() => <></>}
      initialState={{
        sorting: [{ id: 'requested_on', desc: true }],
        columnVisibility: {
          ...columnsHiddenByDefault,
        },
      }}
    />
  );
};
