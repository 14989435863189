import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { searchPayments } from "../../api/payments";
import {
  PaymentsSearchResponse,
  PaymentsSearchRequest,
} from "@paytently/types";

import { objectHash } from "ohash";

type TransformedPaymentsSearchResponse = PaymentsSearchResponse & {
  pageParams: any;
  pages: InfiniteData<PaymentsSearchResponse, unknown>["pages"];
};

export function useSearchPayments({
  pagination,
  filters,
  sorting,
  enabled,
  additionalKey,
}: PaymentsSearchRequest & { enabled?: boolean; additionalKey?: string }) {
  const query = useInfiniteQuery({
    enabled: enabled,
    queryKey: [
      "payments-search",
      pagination.page,
      objectHash(filters),
      additionalKey,
    ],
    initialPageParam: 0,

    getNextPageParam: (lastPage) =>
      lastPage.pagination.has_next_page
        ? lastPage.pagination.page + 1
        : undefined,
    queryFn: ({ pageParam = 0 }: { pageParam?: any }) =>
      searchPayments({
        pagination: { ...pagination, page: pageParam },
        filters,
        sorting,
      }),
    select: (data): TransformedPaymentsSearchResponse => ({
      pageParams: data.pageParams,
      pages: data.pages,
      items: data.pages.flatMap((page) => page.items),
      pagination: data.pages[data.pages.length - 1].pagination, // Latest pagination state
    }),
  });

  return query;
}
