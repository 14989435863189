import { createFileRoute, Outlet } from '@tanstack/react-router';

import { useDocumentTitle } from '@#/hooks';
import { PaymentsPageLayout } from '@#/layouts';

export const Route = createFileRoute('/_authenticated/payments')({
  component: RouteComponent,
});

function RouteComponent() {
  useDocumentTitle('Payments');
  return <PaymentsPageLayout />;
}
