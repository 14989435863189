import { Stack, Image } from '@mantine/core';

import { Text } from '@paytently/ui';

import NoResults from './NoResults.svg?react';

export function EmptyState() {
  return (
    <Stack align="center" gap="lg" py={80}>
      <NoResults />

      <Text ta="center">
        There are no records to show. <br /> Please try another search or filter again.
      </Text>
    </Stack>
  );
}
