import { createFileRoute } from '@tanstack/react-router';

import { useDocumentTitle } from '@#/hooks';
import { PayoutsPage } from '@#/pages';

export const Route = createFileRoute('/_authenticated/payouts')({
  component: RouteComponent,
});

function RouteComponent() {
  useDocumentTitle('Payouts');
  return <PayoutsPage />;
}
