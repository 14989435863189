export const declineCodes = {
  "": "N/A",
  "0001": "Refer to card issuer",
  "0002": "Refer to card issuer, special condition",
  "0003": "Invalid merchant",
  "0004": "Pick up card (no fraud)",
  "0005": "Do not honor",
  "0006": "Error",
  "0007": "Pick up card, special condition (fraud account)",
  "0008": "Honor with ID",
  "0010": "Partial approval",
  "0011": "Approved (V.I.P)",
  "0012": "Invalid transaction",
  "0013": "Invalid amount",
  "0014": "Invalid card/account number",
  "0015": "Invalid issuer",
  "0019": "Re-enter transaction",
  "0021": "No action taken",
  "0025": "Unable to locate record in file",
  "0028": "File temporarily not available for update or inquiry",
  "0030": "Format error",
  "0039": "No credit account",
  "0041": "Lost card",
  "0043": "Stolen card",
  "0046": "Closed account",
  "0051": "Insufficient funds",
  "0052": "No checking account",
  "0053": "No savings account",
  "0054": "Expired card",
  "0055": "Invalid/Missing PIN",
  "0057": "Transaction not permitted to cardholder",
  "0058": "Transaction not permitted to acquirer",
  "0059": "Suspected fraud",
  "0061": "Exceeds withdrawal/approval amount limit",
  "0062": "Restricted card",
  "0063": "Security violation",
  "0064": "Transaction does not fulfill AML requirement",
  "0065": "Exceeds withdrawal count limit",
  "0070": "Contact card issuer",
  "0071": "PIN not changed",
  "0074": "Different value than that used for PIN encryption errors",
  "0075": "Allowable number of PIN tries exceeded",
  "0076": 'Invalid "To Account" / Unsolicited reversal',
  "0077": 'Invalid "From Account"',
  "0078": "Invalid Account / Blocked - First use",
  "0079": "Life cycle",
  "0080": "Network error",
  "0081": "Domestic debit not allowed",
  "0082": "Invalid CVV / Declined due to policy",
  "0083": "Fraud/Security",
  "0084": "Invalid authorization life cycle",
  "0085": "Not declined",
  "0086": "PIN validation not possible",
  "0087": "No cash back allowed",
  "0088": "Cryptographic failure",
  "0089": "Ineligible to receive financial position information",
  "0090": "Cutoff is in progress",
  "0091": "Issuer system inoperative",
  "0092": "Unable to route transaction",
  "0093": "Transaction cannot be completed - Violation of law",
  "0094": "Duplication transaction detected",
  "0096": "System error",
  "001Z": "Authorization system or issuer system inoperative",
  "001A": "Additional customer authentication required",
  "006P": "Verification data failed",
  "00B1": "Surcharge amount not permitted on Visa cards or EBT food stamps",
  "00B2": "Surcharge amount not supported by debit network issuer.",
  "00N0": "Force STIP",
  "00N3": "Cash service not available",
  "00N4": "Cash request exceeds issuer or approved limit",
  "00N5": "Ineligible for resubmission",
  "00N7": "Decline for CVV2 failure",
  "00N8": "Transaction amount exceeds preauthorized approval amount",
  "00P5": "Denied PIN unblock—PIN change or unblock request declined by issuer",
  "00P6": "Denied PIN change—requested PIN unsafe",
  "00Q1": "Card authentication failed",
  "00R0": "Stop payment order",
  "00R1": "Revocation of authorization order",
  "00R2": "Transaction does not qualify for Visa PIN",
  "00R3": "Revocation of all authorizations order",
  "00Z3": "Unable to go online; offline-declined",
  "1000": "Blacklisted",
  "1010": "Fraud policy",
  "1020": "Limit reached",
  "1030": "Payment country not allowed",
  "1040": "Client not allowed",
  "1050": "Temporary block",
};
export type DeclineCode = keyof typeof declineCodes;

export const failCodes = {
  "3000": "Refund failed",
  "3001": "Void failed",
  "9999": "Something went wrong",
};
export type FailCode = keyof typeof failCodes;
