import { datadogRum } from '@datadog/browser-rum';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { createRouter, RouterProvider } from '@tanstack/react-router';

import { routeTree } from '@#/routeTree.gen';

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: undefined!,
    login: undefined!,
    getToken: undefined!,
    userPermissions: undefined!,
    userRoles: undefined!,
    isInternal: undefined!,
    entityId: undefined!,
  },
});

export default function RouterWithAuthContext() {
  const { isAuthenticated, isLoading, login, user, getToken, getPermissions, getClaim } =
    useKindeAuth();

  if (isLoading) return null;

  if (user?.id) datadogRum.setUser({ id: user.id });

  const { permissions: userPermissions } = (getPermissions && getPermissions()) || [];
  const { value: userRoles } = getClaim('roles') || [];
  const { value: isInternal } = getClaim('ptly_internal_actor') || false;
  const { value: entityId } = getClaim('ptly_entity_id') || '';

  return (
    <RouterProvider
      router={router}
      context={{
        isAuthenticated,
        login,
        getToken,
        userPermissions,
        userRoles,
        isInternal,
        entityId,
      }}
    />
  );
}
