import { CurrencyCodes } from '@paytently/types';

export function getFormattedCurrency({
  amount,
  currencyCode,
}: {
  amount: number;
  currencyCode: CurrencyCodes;
}) {
  if (!currencyCode) {
    return null;
  }
  const options: Intl.NumberFormatOptions = { style: 'currency', currency: currencyCode };
  return new Intl.NumberFormat('de-DE', options).format(amount);
}
