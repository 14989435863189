import { AddOrEditUserForm, formSchema } from './AddOrEditUserForm';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useEffect } from 'react';

import { Button, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useEditUser, useGetUserById } from '@paytently/api';
import { UserResponse } from '@paytently/types';
import { ModalActions, SnackbarBody, Title } from '@paytently/ui';

export function EditUserModal({
  context,
  id,
  innerProps,
}: ContextModalProps<{ userDetails: UserResponse }>) {
  const onClose = () => context.closeModal(id);
  const editUser = useEditUser();
  const addOrEditUserForm = useForm({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validate: zodResolver(formSchema),
  });

  const { data } = useGetUserById(innerProps.userDetails.id);

  useEffect(() => {
    if (data) {
      addOrEditUserForm.setValues({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      });
    }
  }, [data]);

  const handleEditUser = async (values: typeof addOrEditUserForm.values) => {
    try {
      await editUser.mutateAsync({
        userId: data?.id || '',
        user: { first_name: values.first_name, last_name: values.last_name },
      });

      modals.closeAll();

      notifications.show({
        message: <SnackbarBody message={'The user’s details have been updated successfully'} />,
        position: 'bottom-center',
      });
    } catch (error) {
      notifications.clean();

      modals.closeAll();

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in updating the user’s details',
          text: 'There was an unexpected error in updating the user’s details. We apologise for the inconvenience. Please talk to your customer support manager.',
        },
      });
    }
  };

  return (
    <>
      <Stack gap="lg" pb="lg">
        <Title order={2} weight="medium">
          Edit user
        </Title>

        <AddOrEditUserForm form={addOrEditUserForm} onSubmit={handleEditUser} isEmailDisabled />
      </Stack>

      <ModalActions>
        <Button onClick={onClose} size="md" variant="subtle" color="neutral.3">
          Cancel
        </Button>
        <Button
          disabled={!addOrEditUserForm.isValid()}
          form="add-or-edit-user-form"
          type="submit"
          size="md"
        >
          Save
        </Button>
      </ModalActions>
    </>
  );
}
