import { connectorConfiguration } from './connectorConfiguration';

import { ConnectorChannel } from '@paytently/types';

export const getConnectorChannelByChannelId = ({
  channelId,
}: {
  channelId: string;
}): ConnectorChannel | null => {
  for (const connector of connectorConfiguration) {
    const channel = connector.channels.find(({ id }) => id === channelId);

    if (channel) return channel;
  }

  return null;
};

export const getConnectorByChannelId = (channelId: string) => {
  const connector = connectorConfiguration.find(({ channels }) =>
    channels.find((channel) => channel.id === channelId)
  );

  if (!connector) return null;

  return connector;
};
