import { Permissions } from '@paytently/authentication';

type ResourceMapper = {
  [key: string]: Permissions;
};
export const pathnameToResourcePermissionsMapper: ResourceMapper = {
  '/': Permissions['dashboard:read'],
  '/user_management': Permissions['usermanagement:read'],
  '/payments': Permissions['payments:read'],
  '/payments/$paymentId': Permissions['payments:read'],
  '/details/$paymentId': Permissions['payments:read'],
  '/payouts': Permissions['payouts:read'],
};
