import { PaymentMethodCell } from './PaymentsTableCells';
import { useNavigate } from '@tanstack/react-router';
import { MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import { useMemo } from 'react';

import { Menu } from '@mantine/core';

import { Permissions } from '@paytently/authentication';
import { PaymentsSearchResponseItem, Status } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { DataTable } from '@#/components/DataTable';
import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import CreditCardOff from '@material-symbols/svg-400/rounded/credit_card_off.svg?react';
import QuickReferenceAll from '@material-symbols/svg-400/rounded/quick_reference_all.svg?react';

import {
  AmountDataTableCell,
  ChannelDataTableCell,
  DateDataTableCell,
  DisplayWithPaymentStatus,
  GenericDataTableCell,
  StatusDataTableCell,
} from '@#/components';
import { RequiresPermissions } from '@#/utils/RequiresPermission';

export type PaymentsDataTableItem = Pick<
  PaymentsSearchResponseItem,
  'id' | 'status' | 'amount' | 'customer' | 'method' | 'requested_on' | 'route' | 'amount_details'
>;

export const PaymentsTable = ({
  data,
  tableBodyRef,
  isLoading,
}: {
  data: PaymentsSearchResponseItem[];
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  isLoading: boolean;
}) => {
  const { isInMobileMode, isInTabletMode } = useDisplayMode();
  const navigate = useNavigate({ from: '/payments' });

  const columns = useMemo<MRT_ColumnDef<PaymentsDataTableItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Transaction ID',
        enableSorting: false,
        grow: false,
        size: 256,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        Cell: StatusDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorKey: 'requested_on',
        header: 'Date',
        Cell: DateDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        Cell: AmountDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorKey: 'route',
        header: 'Channel',
        enableSorting: false,
        Cell: ChannelDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorFn: (row) => row.route?.external_transaction_id ?? undefined,
        header: 'External ID',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorFn: (row) => row.method?.type ?? undefined,
        header: 'Payment method',
        enableSorting: false,
        Cell: PaymentMethodCell<PaymentsDataTableItem>,
      },
      {
        id: 'open-banking-country',
        accessorFn: (row) => row.method?.open_banking?.account?.country ?? undefined,
        header: 'Bank account country',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        id: 'open-banking-account-number',
        accessorFn: (row) => row.method?.open_banking?.account?.number ?? undefined,
        header: 'Bank account number',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        id: 'open-banking-bic',
        accessorFn: (row) => row.method?.open_banking?.account?.bic ?? undefined,
        header: 'BIC',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        id: 'open-banking-account-type',
        accessorFn: (row) => row.method?.open_banking?.account?.type ?? undefined,
        header: 'Bank account type',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      {
        accessorFn: (row) =>
          row.customer ? `${row.customer?.first_name} ${row.customer?.last_name}` : undefined,
        header: 'Customer name',
        enableSorting: false,
        Cell: GenericDataTableCell<PaymentsDataTableItem>,
      },
      // TODO - Reintroduce when supported by API: https://paytentlydev.atlassian.net/browse/COPS-799
      // {
      //   accessorFn: (row) => `${row.method?.scheme}`,
      //   header: 'Scheme',
      //   enableSorting: false,
      //   grow: false,
      //   Cell: SchemeCell,
      // },
    ],
    [isInMobileMode]
  );

  const columnsHiddenByDefault = {
    'open-banking-country': false,
    'open-banking-account-number': false,
    'open-banking-bic': false,
    'open-banking-account-type': false,
  };

  const onTransactionClick = (row: MRT_Row<PaymentsDataTableItem>, fullView?: boolean) => {
    if (isInTabletMode || isInMobileMode || fullView) {
      return navigate({ to: `/details/${row.original.id}` });
    }

    return navigate({ to: `/payments/${row.original.id}` });
  };

  const renderRowMenu = ({ row }: { row: MRT_Row<PaymentsDataTableItem> }) => {
    return (
      <>
        <Menu.Item
          onClick={() => onTransactionClick(row, true)}
          leftSection={<QuickReferenceAll width={20} />}
        >
          View details
        </Menu.Item>
        <RequiresPermissions checkPermission={Permissions['void:request']}>
          <DisplayWithPaymentStatus
            paymentStatus={row.original.status}
            withStatuses={[Status.authorized]}
          >
            <Menu.Item
              leftSection={<CreditCardOff width={20} />}
              onClick={() =>
                openMobileOrDesktopModal(isInMobileMode, {
                  modal: 'void',
                  size: '512px',
                  innerProps: {
                    paymentId: row.original.id,
                  },
                })
              }
            >
              Void
            </Menu.Item>
          </DisplayWithPaymentStatus>
        </RequiresPermissions>
        <RequiresPermissions checkPermission={Permissions['refund:request']}>
          <DisplayWithPaymentStatus
            paymentStatus={row.original.status}
            withStatuses={[Status.captured]}
          >
            <Menu.Item
              leftSection={<CreditCardOff width={20} />}
              disabled={row.original.amount_details.available_to_refund <= 0}
              onClick={() =>
                openMobileOrDesktopModal(isInMobileMode, {
                  modal: 'refund',
                  size: '512px',
                  innerProps: {
                    paymentId: row.original.id,
                  },
                })
              }
            >
              Refund
            </Menu.Item>
          </DisplayWithPaymentStatus>
        </RequiresPermissions>
      </>
    );
  };

  return (
    <DataTable<PaymentsDataTableItem>
      data={data}
      columns={columns}
      isLoading={isLoading}
      onRowClick={onTransactionClick}
      tableBodyRef={tableBodyRef}
      renderRowMenu={renderRowMenu}
      initialState={{
        sorting: [{ id: 'requested_on', desc: true }],
        columnVisibility: {
          ...columnsHiddenByDefault,
        },
      }}
    />
  );
};
