import { RenderChildrenOrPlaceHolder } from './RenderChildrenOrPlaceHolder';
import { MRT_Cell, MRT_RowData } from 'mantine-react-table';

export function GenericDataTableCell<T extends MRT_RowData>({ cell }: { cell: MRT_Cell<T> }) {
  return (
    <RenderChildrenOrPlaceHolder<T> cell={cell}>
      {cell.getValue<string>()}
    </RenderChildrenOrPlaceHolder>
  );
}
