import { MemoryStorage, setActiveStorage, StorageKeys } from "@kinde/js-utils";

const memoryStorage = new MemoryStorage();

setActiveStorage(memoryStorage);

async function setAccessToken(accessToken: string) {
  return await memoryStorage.setSessionItem(
    StorageKeys.accessToken,
    accessToken,
  );
}

async function getAccessToken() {
  return await memoryStorage.getSessionItem(StorageKeys.accessToken);
}

export { setAccessToken, getAccessToken };
