import { pathnameToResourcePermissionsMapper } from './pathnameToResourcePermissionsMapper';
import { notFound, redirect } from '@tanstack/react-router';

import { hasPermission } from '@paytently/authentication';
import { AuthContext } from '@paytently/types';

interface RouteGuard {
  fullPath: string;
  authContext: AuthContext;
  shouldThrowAuthErrors?: boolean;
}

export function routeGuard({ fullPath, authContext, shouldThrowAuthErrors = true }: RouteGuard) {
  const { isAuthenticated, userPermissions } = authContext;

  if (!isAuthenticated) {
    throw redirect({ to: '/login' });
  }

  const resourcePermission = pathnameToResourcePermissionsMapper[fullPath];

  if (!resourcePermission) {
    if (shouldThrowAuthErrors) throw notFound();

    return false;
  }

  const hasAccessToRoute = hasPermission(resourcePermission, userPermissions);

  if (!hasAccessToRoute) {
    if (shouldThrowAuthErrors) throw notFound();

    return false;
  }

  return true;
}
