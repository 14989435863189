import { Button, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { Title, Text, ModalActions } from '@paytently/ui';

import ErrorIcon from '@material-symbols/svg-400/rounded/error.svg?react';

export function ErrorModal({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  title: string;
  text: string;
  errorColor?: string;
}>) {
  return (
    <Stack gap="md">
      <Stack align="center" gap="md">
        <ErrorIcon fill={innerProps.errorColor || 'var(--mantine-color-red-0)'} />
        <Title order={2} weight="medium">
          {innerProps.title}
        </Title>
      </Stack>
      <Text ta="center">{innerProps.text}</Text>
      <ModalActions>
        <Button
          component="a"
          color="neutral.3"
          variant="subtle"
          size="md"
          href="https://www.paytently.com/contact-us"
          target="_blank"
        >
          Get support
        </Button>
        <Button
          color="secondary.1"
          variant="subtle"
          size="md"
          onClick={() => context.closeModal(id)}
        >
          Close
        </Button>
      </ModalActions>
    </Stack>
  );
}
