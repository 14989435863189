import { ReactNode } from 'react';

import { hasPermission, Permissions } from '@paytently/authentication';

import { useAuthContext } from '@#/hooks';

export function useHasPermission({ checkPermission }: { checkPermission: Permissions }) {
  const { userPermissions } = useAuthContext();
  return hasPermission(checkPermission, userPermissions);
}

export function RequiresPermissions({
  checkPermission,
  children,
}: {
  checkPermission: Permissions;
  children: ReactNode;
}) {
  const userHasPermission = useHasPermission({ checkPermission });
  return userHasPermission && children;
}
