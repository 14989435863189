import { Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useResetPassword as useResetPasswordAPI } from '@paytently/api';
import { UserResponse } from '@paytently/types';
import { SnackbarBody, Text, Title } from '@paytently/ui';

import InfoIcon from '@material-symbols/svg-400/rounded/info.svg?react';

export function useResetPassword() {
  const resetPasswordMutation = useResetPasswordAPI();

  const openResetPasswordModal = ({ userDetails }: { userDetails: UserResponse }) => {
    modals.openContextModal({
      modal: 'confirm',
      centered: true,
      innerProps: {
        children: (
          <>
            <Stack align="center" gap="md">
              <InfoIcon fill="var(--mantine-color-yellow-0)" />
              <Title order={2} ta="center">
                Reset password
              </Title>
            </Stack>
            <Text ta="center">
              Are you sure you want to reset password for{' '}
              <Text span weight="bold">
                {userDetails.first_name} {userDetails.last_name}
              </Text>
              ? The user will receive an email prompting them to create a new password.
            </Text>
          </>
        ),
        onConfirm: () => resetPassword({ userDetails }),
        onCancel: () => modals.closeAll(),
        labels: { confirm: 'Reset' },
      },
    });
  };

  const resetPassword = async ({ userDetails }: { userDetails: UserResponse }) => {
    modals.closeAll();

    try {
      await resetPasswordMutation.mutateAsync({ userId: userDetails.id });

      notifications.show({
        message: (
          <SnackbarBody
            message={`A password reset email has been sent to ${userDetails.first_name} ${userDetails.last_name} `}
          />
        ),
        position: 'bottom-center',
      });
    } catch (error) {
      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: "Error in resetting user's password",
          text: 'There was an unexpected error in the password reset action. We apologise for the inconvenience. If the error appears again please contact your customer support manager.',
        },
      });
    }
  };

  return {
    openResetPasswordModal,
  };
}
