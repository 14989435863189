import { Stack } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useSuspendUser as useSuspendUserAPI } from '@paytently/api';
import { UserResponse } from '@paytently/types';
import { SnackbarBody, Text, Title } from '@paytently/ui';

import DeleteIcon from '@material-symbols/svg-400/rounded/delete.svg?react';

export function useSuspendUser() {
  const suspendUserMutation = useSuspendUserAPI();

  const openSuspendUserModal = ({ userDetails }: { userDetails: UserResponse }) => {
    modals.openContextModal({
      modal: 'confirm',
      centered: true,
      innerProps: {
        children: (
          <>
            <Stack align="center" gap="md">
              <DeleteIcon fill="var(--mantine-color-red-0)" width={32} height={32} />
              <Title order={2} ta="center">
                Delete user
              </Title>
            </Stack>
            <Text ta="center">
              Are you sure you want to delete{' '}
              <Text span weight="bold">
                {userDetails.first_name} {userDetails.last_name}
              </Text>
              ? The user can be added again using the “add user” button.
            </Text>
          </>
        ),
        onConfirm: () => suspendUser({ userDetails }),
        onCancel: () => modals.closeAll(),
        labels: { confirm: 'Delete' },
        buttonProps: { confirm: { color: 'var(--mantine-color-red-0' } },
      },
    });
  };

  const suspendUser = async ({ userDetails }: { userDetails: UserResponse }) => {
    modals.closeAll();

    try {
      await suspendUserMutation.mutateAsync({ userId: userDetails.id });
      notifications.show({
        message: (
          <SnackbarBody
            message={`${userDetails.first_name} ${userDetails.last_name} has been deleted successfully`}
          />
        ),
        position: 'bottom-center',
      });
    } catch (error) {
      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in deleting user',
          text: 'There was an unexpected error in removing this user. We apologise for the inconvenience. If the error appears again please contact your customer support manager.',
        },
      });
    }
  };

  return {
    openSuspendUserModal,
  };
}
