import { createFileRoute } from '@tanstack/react-router';

import { useDocumentTitle } from '@#/hooks';
import { Dashboard } from '@#/pages';

export const Route = createFileRoute('/_authenticated/')({
  component: RouteComponent,
});

function RouteComponent() {
  useDocumentTitle('Home');
  return <Dashboard />;
}
