import { CreateUserModal, EditUserModal } from './AddOrEditUserModal';
import { ConfirmModal } from './ConfirmModal';
import { ErrorModal } from './ErrorModal';
import { ExportReportTermsModal } from './ExportReportTermsModal';
import { RefundModal } from './RefundModal';
import { VoidModal } from './VoidModal';

export const modals = {
  exportReportTerms: ExportReportTermsModal,
  refund: RefundModal,
  void: VoidModal,
  error: ErrorModal,
  confirm: ConfirmModal,
  createUser: CreateUserModal,
  editUser: EditUserModal,
};
