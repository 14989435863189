import { Scheme, SchemeType } from '@paytently/types';
import Mastercard from '@paytently/ui/src/assets/schemes/mastercard.svg?react';
import Visa from '@paytently/ui/src/assets/schemes/visa.svg?react';

const cardSchemeIconProps = { height: 24, width: 40 };

const cardSchemeIcons = {
  [Scheme.mastercard]: <Mastercard {...cardSchemeIconProps} />,
  [Scheme.visa]: <Visa {...cardSchemeIconProps} />,
};

interface Props {
  scheme: SchemeType;
}
export function CardSchemeIcon({ scheme }: Props) {
  return cardSchemeIcons[scheme];
}
