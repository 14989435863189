import { PaymentActionButton } from './PaymentActionButton';
import { Link } from '@tanstack/react-router';

import { Group } from '@mantine/core';

import { PaymentResponse } from '@paytently/types';
import { TextButton } from '@paytently/ui';

import ArrowBackIcon from '@material-symbols/svg-400/rounded/arrow_back_ios.svg?react';

import { useIsPaymentsQuickViewRoute } from '@#/hooks';

import styles from './TransactionDetailsHeader.module.css';

interface Props {
  paymentData: PaymentResponse;
}

export function TransactionDetailsHeader({ paymentData }: Props) {
  const isPaymentsQuickView = useIsPaymentsQuickViewRoute();

  return (
    <>
      <Group justify="space-between" classNames={{ root: styles.root }}>
        {!isPaymentsQuickView && (
          <TextButton
            component={Link}
            style={{ justifySelf: 'flex-start' }}
            color="neutral.2"
            icon={<ArrowBackIcon />}
            to="/payments"
          >
            Back to Payments
          </TextButton>
        )}

        <PaymentActionButton paymentData={paymentData} />
      </Group>
    </>
  );
}
