import { RenderChildrenOrPlaceHolder } from './RenderChildrenOrPlaceHolder';
import { MRT_Cell, MRT_RowData } from 'mantine-react-table';

import { useFormattedDate } from '@#/hooks';

export function DateDataTableCell<T extends MRT_RowData>({ cell }: { cell: MRT_Cell<T> }) {
  return (
    <RenderChildrenOrPlaceHolder cell={cell}>
      {useFormattedDate({ date: cell.getValue<string>() })}
    </RenderChildrenOrPlaceHolder>
  );
}
