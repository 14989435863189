import { mergeDeepRight, pick } from 'ramda';

import { AppliedFilters } from '@#/components/Filters/FilterTypes';

type GenericObjectType<T> = {
  [key: string]: T;
};

export const getGenericStringArray = (data: string[], filterPropertyKey: string) => ({
  [filterPropertyKey]: data,
});

export const generateMinMaxSearchFilter = (
  { min, max }: { min?: number; max?: number },
  filterPropertyKey: string
) => ({ [filterPropertyKey]: { min, max } });

export const generateObject = <T>(data: T, keys: string | string[]): GenericObjectType<T> => {
  const keyArray = Array.isArray(keys) ? keys : [keys];

  return keyArray.reduceRight(
    (acc, key) => ({ [key]: acc }),
    data
  ) as unknown as GenericObjectType<T>;
};

/**
 * Generates and object of key value pairs,
 * and optionally merges it with a previous object
 * if the root level key names are the same.
 */
export const generateOrUpdateObject = <T>(
  data: T,
  previousFilters: AppliedFilters,
  keys: string | string[]
): GenericObjectType<T> => {
  const keyArray = Array.isArray(keys) ? keys : [keys];

  if (previousFilters) {
    let existingSearchFilter = {};
    const previousFiltersArray = Object.values(previousFilters);

    previousFiltersArray.map(({ searchFilter }) => {
      if (!searchFilter) return null;

      const [rootLevelProperty] = keyArray;

      existingSearchFilter = pick([rootLevelProperty], searchFilter);
    });

    return mergeDeepRight(
      existingSearchFilter,
      generateObject(data, keyArray)
    ) as unknown as GenericObjectType<T>;
  }

  return generateObject(data, keyArray);
};
