import { RenderChildrenOrPlaceHolder } from './RenderChildrenOrPlaceHolder';
import { MRT_Cell, MRT_RowData } from 'mantine-react-table';

import { getConnectorChannelByChannelId } from '@#/utils';

export function ChannelDataTableCell<T extends MRT_RowData>({ cell }: { cell: MRT_Cell<T> }) {
  const route = cell.getValue<T['route']>();
  const channel = getConnectorChannelByChannelId({
    channelId: route?.channel?.id,
  });

  return <RenderChildrenOrPlaceHolder cell={cell}>{channel?.name}</RenderChildrenOrPlaceHolder>;
}
