import { useEffect, useState } from 'react';

import { useLocalStorage } from '@mantine/hooks';
import { modals } from '@mantine/modals';

import { useSearchPayments } from '@paytently/api';
import { TextButton } from '@paytently/ui';

import DownloadIcon from '@material-symbols/svg-400/rounded/file_save.svg?react';

import { useDownloadJsonAsCsv } from '@#/hooks';

interface Props {
  filters: { requested_on: { from: string; to: string }; [x: string]: any };
}

export function ExportReport({ filters }: Props) {
  const { refetch, data, fetchNextPage, isFetching, hasNextPage } = useSearchPayments({
    additionalKey: 'export',
    enabled: false,
    pagination: { page: 0, size: 50 },
    filters,
  });

  const [awaitingFetch, setAwaitingFetch] = useState(false);

  const [hasAcceptedExportReportTerms, setHasAcceptedExportReportTerms] = useLocalStorage({
    key: 'export-payments-report-terms-accepted',
    defaultValue: 'false',
  });

  const { downloadCsv } = useDownloadJsonAsCsv(data?.items, 'my-data.csv');

  const startFetching = () => setAwaitingFetch(true);
  // As each fetch finishes, check if there is another page available and fetch that. If no pages left, download the fetched data.
  useEffect(() => {
    if (awaitingFetch && !isFetching) {
      if (!data) {
        refetch();
      } else if (hasNextPage) {
        fetchNextPage();
      } else if (!hasNextPage) {
        downloadCsv();
        setAwaitingFetch(false);
      }
    }
  }, [awaitingFetch, hasNextPage, isFetching]);

  async function handleExportReportClick() {
    if (hasAcceptedExportReportTerms === 'false') {
      return modals.openContextModal({
        modal: 'exportReportTerms',
        innerProps: {
          confirmAction: () => startFetching(),
          setTermsCheckedPreference: setHasAcceptedExportReportTerms,
        },
      });
    } else {
      startFetching();
    }
  }

  return (
    <TextButton ml="auto" icon={<DownloadIcon />} onClick={handleExportReportClick}>
      Export report
    </TextButton>
  );
}
