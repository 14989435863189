import { path } from 'ramda';

import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { DeclineCode, declineCodes, FailCode, failCodes } from '@paytently/api';
import { Permissions } from '@paytently/authentication';
import { PaymentResponse, Status } from '@paytently/types';
import { SnackbarBody } from '@paytently/ui';

import { useIsInternal } from '@#/hooks/useIsInternal';
import { getConnectorByChannelId, getConnectorChannelByChannelId } from '@#/utils';

export function getFailDescription(code: FailCode): string {
  return failCodes[code];
}
export function getDeclineDescription(code: DeclineCode): string {
  return declineCodes[code];
}

type CSVdataMapper =
  | {
      header: string;
      dataPoint: string[];
    }
  | { header: string; dataFunction: (row: PaymentResponse) => string };

// TODO: Refactor out of custom hook
const csvDataMapper: CSVdataMapper[] = [
  {
    header: 'Date & time',
    dataPoint: ['requested_on'],
  },
  {
    header: 'Payment ID',
    dataPoint: ['id'],
  },
  { header: 'Reference', dataPoint: ['reference'] },
  {
    header: 'Status',
    dataPoint: ['status'],
  },
  {
    header: 'Decline Code',
    dataFunction: (row) => (row.status === Status.declined && row.errors?.[0].code) || '',
  },
  {
    header: 'Decline Reason',
    dataFunction: (row) => {
      const code = (row.errors?.[0].code as DeclineCode) || '';
      return (row.status === Status.declined && getDeclineDescription(code)) || '';
    },
  },
  {
    header: 'Fail Code',
    dataFunction: (row) => (row.status === Status.failed && row.errors?.[0].code) || '',
  },
  {
    header: 'Fail Reason',
    dataFunction: (row) => {
      const code = (row.errors?.[0].code as FailCode) || '';
      return (row.status === Status.failed && getFailDescription(code)) || '';
    },
  },
  {
    header: 'Channel',
    dataFunction: (row) =>
      getConnectorChannelByChannelId({ channelId: row.route?.channel?.id })?.name || '',
  },
  {
    header: 'External ID',
    dataPoint: ['route', 'external_transaction_id'],
  },
  {
    header: 'Payment method',
    dataFunction: (row) =>
      row?.method?.type === 'card'
        ? 'Card'
        : row?.method?.type === 'open_banking'
          ? 'Open banking'
          : '-',
  },
  {
    header: 'Transaction Amount',
    dataPoint: ['amount'],
  },
  {
    header: 'Captured Amount',
    dataPoint: ['amount_details', 'total_captured'],
  },
  {
    header: '3DS Check',
    dataFunction: (row) => (row['3ds'] ? 'true' : 'false'),
  },
  {
    header: 'Customer Name',
    dataFunction: (row) => `${row.customer.first_name} ${row.customer.last_name}`,
  },
  {
    header: 'Birth Date',
    dataPoint: ['customer', 'birth_date'],
  },
  {
    header: 'Email address',
    dataPoint: ['customer', 'contact', 'email'],
  },
  {
    header: 'Phone number',
    dataPoint: ['customer', 'contact', 'phone', 'number'],
  },
  {
    header: 'Billing country',
    dataPoint: ['billing_address', 'country'],
  },
  {
    header: 'Card Number',
    dataPoint: ['customer', 'contact', 'phone', 'number'],
  },
  {
    header: 'Bank account country',
    dataPoint: ['method', 'open_banking', 'account', 'country'],
  },
  {
    header: 'Bank account number',
    dataPoint: ['method', 'open_banking', 'account', 'number'],
  },
  {
    header: 'BIC',
    dataPoint: ['method', 'open_banking', 'account', 'bic'],
  },
  {
    header: 'Bank account type',
    dataPoint: ['method', 'open_banking', 'account', 'type'],
  },
  {
    header: 'Void date & time',
    dataFunction: () => 'TODO',
  },
  {
    header: 'Void status',
    dataFunction: () => 'TODO',
  },
  {
    header: 'Refund date & time',
    dataFunction: () => 'TODO',
  },
  {
    header: 'Refund amount',
    dataFunction: () => 'TODO',
  },
  {
    header: 'Refunded status',
    dataFunction: () => 'TODO',
  },
];

const internalOnlyCsvDataMapper: CSVdataMapper[] = [
  {
    header: 'Connector',
    dataFunction: (row) => getConnectorByChannelId(row.route?.channel?.id)?.name || '',
  },
];

export const useDownloadJsonAsCsv = (data: PaymentResponse[], fileName: string) => {
  const isInternal = useIsInternal();

  const convertJSONToCSV = (data: PaymentResponse[]) => {
    try {
      const combinedDataMapper = [
        ...csvDataMapper,
        ...(isInternal ? internalOnlyCsvDataMapper : []),
      ];

      const headers = combinedDataMapper.map(({ header }) => header);
      const csvRows = data.map((row) =>
        combinedDataMapper
          .map((column) => {
            return 'dataPoint' in column ? path(column.dataPoint, row) : column.dataFunction(row);
          })
          .join(',')
      );

      return [headers.join(','), ...csvRows].join('\n');
    } catch (error) {
      throw new Error('Failed to convert data to CSV format.');
    }
  };

  const downloadCsv = () => {
    try {
      if (!data || data.length < 1) {
        throw new Error('No data available to download');
      }

      const csvData = convertJSONToCSV(data);

      notifications.show({
        message: <SnackbarBody message="Processing..." />,
        position: 'bottom-center',
      });

      // Create a Blob from the CSV
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to download the file
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Programmatically trigger the link click to initiate a file download
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the Object URL and removing the link
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error: any) {
      const errorMessage = error?.message ?? 'Failed to download report';

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Export error',
          text: 'There was an unexpected error in exporting report. We apologise for the inconvenience. If the error appears again please contact your customer support manager.',
          errorColor: 'var(--mantine-color-yellow-0)',
        },
      });
    }
  };

  return { downloadCsv };
};
