import { ReactNode } from 'react';

import { StatusType } from '@paytently/types';

interface Props {
  withStatuses: StatusType[];
  paymentStatus: StatusType;
  children: ReactNode;
}

export function DisplayWithPaymentStatus({ withStatuses, paymentStatus, children }: Props) {
  if (withStatuses.includes(paymentStatus)) {
    return children;
  }

  return null;
}
