import { useEffect } from 'react';

import { PaginationInfo } from '@paytently/types/src/Common';

/**
 * Requests the next page of a paginated response when the middle element of the last loaded page scrolls into view.
 */
export const useInfiniteScroll = ({
  observerTarget,
  pages,
  pagination,
  isFetchingNextPage,
  fetchNextPage,
}: {
  observerTarget: React.RefObject<any>;
  pages: any[];
  pagination?: PaginationInfo;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
}) => {
  useEffect(() => {
    if (!pages || !pagination) return;

    // Find the middle index of the current last page
    const items = pages[pages.length - 1]?.items || [];
    const itemCountOfPreviousPages = pagination.size * pagination.page;
    const middleIndex = Math.floor(items.length / 2) + itemCountOfPreviousPages;

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];

        // Load the next page once the middle of this one becomes visible
        if (target.isIntersecting && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        threshold: 0.1, // Triggers when a small part of the target is visible
      }
    );

    const middleElement = observerTarget.current?.children?.[middleIndex];
    if (middleElement) {
      observer.observe(middleElement);
    }

    return () => observer.disconnect();
  }, [pages, fetchNextPage, isFetchingNextPage]);
};
