import { openContextModal } from '@mantine/modals';
import { OpenContextModal } from '@mantine/modals/lib/context';

export const openMobileOrDesktopModal = (
  isInMobileMode: boolean,
  payload: OpenContextModal<any> & {
    modal: string;
  }
) => {
  return openContextModal({
    ...payload,
    fullScreen: isInMobileMode,
    transitionProps: { transition: isInMobileMode ? 'slide-left' : 'pop' },
  });
};
