import { addDays, startOfDay } from 'date-fns';
import { mergeDeepRight } from 'ramda';
import { useMemo } from 'react';

import { DatePickerValue } from '@mantine/dates';
import { useSessionStorage } from '@mantine/hooks';

import { SearchInputMenuOption } from '@paytently/ui';

import { AppliedFilters } from '@#/components/Filters/FilterTypes';

import { paymentFiltersByName } from '@#/pages/Payments/PaymentFilters';

export function usePaymentsFiltering() {
  const [appliedFilters, setAppliedFilters] = useSessionStorage<AppliedFilters>({
    key: 'payments-filters',
    defaultValue: {},
  });

  const [searchBarFilter, setSearchBarFilter] = useSessionStorage<
    | undefined
    | {
        option: SearchInputMenuOption;
        value: string;
      }
  >({
    key: 'payments-searchbar-filter',
    getInitialValueInEffect: false,
  });

  const searchBarFilterJson = searchBarFilter?.option
    ? { [searchBarFilter.option.value]: searchBarFilter.value }
    : {};

  const [dateRange, setDateRange] = useSessionStorage<DatePickerValue<'range'>>({
    key: 'payments-date-range-filter',
    getInitialValueInEffect: false,
    defaultValue: [new Date(), new Date()],
  });

  const extractedSearchFilters = useMemo(() => {
    let searchFilters = {};

    for (const [key, value] of Object.entries(appliedFilters)) {
      const generateSearchFilterFunc = paymentFiltersByName[key].generateSearchFilter;
      searchFilters = mergeDeepRight(searchFilters, generateSearchFilterFunc(value.value));
    }

    return searchFilters;
  }, [appliedFilters]);

  const combinedSearchFilters = {
    // from: midnight of selected day
    // to: midnight of day after selected day
    requested_on: {
      from: startOfDay(dateRange[0]!).toISOString(),
      to: startOfDay(addDays(dateRange[1]!, 1)).toISOString(),
    },
    ...searchBarFilterJson,
    ...extractedSearchFilters,
  };

  return {
    appliedFilters,
    setAppliedFilters,
    searchBarFilter,
    setSearchBarFilter,
    dateRange,
    setDateRange,
    combinedSearchFilters,
  };
}
