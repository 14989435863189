import { createFileRoute } from '@tanstack/react-router';

import { TransactionQuickView } from '@#/components';

export const Route = createFileRoute('/_authenticated/payments/$paymentId')({
  component: RouteComponent,
});

function RouteComponent() {
  const { paymentId } = Route.useParams();

  return <TransactionQuickView paymentId={paymentId} />;
}
