import App from './App';
import ReactDOM from 'react-dom/client';

async function enableMocking() {
  // TODO - Turn this on once environment variables are working
  const { worker } = await import('@paytently/api/src/mocks/browser');

  return worker.start({
    onUnhandledRequest(request, print) {
      // Passthrough any requests to the internal assets folder
      if (request.url.includes('src/assets')) {
        return;
      }

      // Otherwise, print an unhandled request warning.
      print.warning();
    },
  });
}

enableMocking().then(() => ReactDOM.createRoot(document.getElementById('root')!).render(<App />));
