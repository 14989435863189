import { Outlet } from '@tanstack/react-router';

import { Payments } from '@#/pages';

export function PaymentsPageLayout() {
  return (
    <>
      <Payments />
      <Outlet />
    </>
  );
}
