import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { AppShell, Button, Divider, Group, Menu, Switch, Text } from '@mantine/core';

import { BottomSheet, MenuItemWithControl } from '@paytently/ui';

// Todo - Replace with relative path to @paytently/ui/assets
import LogoIcon from '../../../../../packages/ui/src/assets/logos/LogoIconLight.svg?react';
import FolderData from '@material-symbols/svg-400/rounded/folder_data.svg?react';
import LabProfile from '@material-symbols/svg-400/rounded/lab_profile.svg?react';
import Logout from '@material-symbols/svg-400/rounded/logout.svg?react';
import Person from '@material-symbols/svg-400/rounded/person.svg?react';
import School from '@material-symbols/svg-400/rounded/school.svg?react';

import { useSession } from '../../contexts/SessionContext';
import { MobileNavigation } from '../MobileNavigation/MobileNavigation';

import styles from './Header.module.css';

const ResponsiveMenu = ({
  inDesktopOrTabletMode,
  desktopTrigger,
  mobileTrigger,
  menuItems,
}: {
  inDesktopOrTabletMode: boolean;
  desktopTrigger: ReactNode;
  mobileTrigger: ReactNode;
  menuItems: ReactNode;
}) => {
  return inDesktopOrTabletMode ? (
    <Menu>
      {desktopTrigger}
      <Menu.Dropdown className={styles.userMenuDropdown}>{menuItems}</Menu.Dropdown>
    </Menu>
  ) : (
    <BottomSheet title="User menu" titleHidden trigger={mobileTrigger} snapPoints={[0.3]}>
      <BottomSheet.List>{menuItems}</BottomSheet.List>
    </BottomSheet>
  );
};

const MenuItems = ({ isInDesktopOrTabletMode }: { isInDesktopOrTabletMode: boolean }) => {
  const { state: sessionState, dispatch } = useSession();
  const { logout } = useKindeAuth();

  return (
    <>
      <Menu.Item leftSection={<FolderData />}>
        <a target="_blank" rel="noreferrer" href="https://docs.paytently.io/api">
          API Documentation
        </a>
      </Menu.Item>
      <Menu.Item leftSection={<LabProfile />}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.paytently.io/legal/terms_conditions/"
        >
          Terms and Conditions
        </a>
      </Menu.Item>
      <MenuItemWithControl
        leftSection={<School />}
        closeMenuOnClick={false}
        controlComponent={
          <Switch
            checked={sessionState.testAccount}
            onChange={(e) => {
              dispatch({
                type: 'setTestAccount',
                payload: e.currentTarget.checked,
              });
            }}
            size="sm"
          />
        }
      >
        Test Account
      </MenuItemWithControl>
      {isInDesktopOrTabletMode && <Divider />}
      <Menu.Item leftSection={<Logout />} onClick={() => logout()}>
        Log out
      </Menu.Item>
    </>
  );
};

export type HeaderProps = {
  isInDesktopOrTabletMode: boolean;
  topOffset?: number;
};

export function Header({ isInDesktopOrTabletMode, topOffset = 0 }: HeaderProps) {
  const { user } = useKindeAuth();
  return (
    <AppShell.Header
      top={topOffset}
      bg={isInDesktopOrTabletMode ? 'inherit' : 'var(--mantine-color-secondary-3)'}
      px="xxs"
    >
      <Group justify="space-between" h="100%">
        {!isInDesktopOrTabletMode && (
          <Group pl="sm">
            <LogoIcon />
            <MobileNavigation />
          </Group>
        )}

        <Group
          className={classNames(styles.userMenu, {
            [styles.mobile]: !isInDesktopOrTabletMode,
          })}
        >
          <ResponsiveMenu
            inDesktopOrTabletMode={isInDesktopOrTabletMode}
            desktopTrigger={
              <Menu.Target>
                <Button
                  color="neutral.4"
                  size="md"
                  variant="subtle"
                  leftSection={<Person title="User menu" width={20} />}
                >
                  <Text size="16px" fw={400}>
                    {user?.email}
                  </Text>
                </Button>
              </Menu.Target>
            }
            mobileTrigger={
              <BottomSheet.Trigger>
                <Person title="User menu" width={20} />
              </BottomSheet.Trigger>
            }
            menuItems={<MenuItems isInDesktopOrTabletMode={isInDesktopOrTabletMode} />}
          />
        </Group>
      </Group>
    </AppShell.Header>
  );
}
