import { useMutation } from "@tanstack/react-query";

import { EditUserRequest } from "@paytently/types";
import { editUser } from "../../api/authentication";

export function useEditUser() {
  const mutation = useMutation({
    mutationFn: ({ userId, user }: { userId: string; user: EditUserRequest }) =>
      editUser({ userId, user }),
  });

  return mutation;
}
