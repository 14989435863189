import { PayoutsTable } from './PayoutsTable';
import { useRef } from 'react';

import { Group, Stack } from '@mantine/core';

import { CountriesByCode, CurrencyCodes, OpenBankingAccountType, Status } from '@paytently/types';
import { PageTitle } from '@paytently/ui';
import PayoutPageIcon from '@paytently/ui/src/assets/icons/PayoutsDark.svg?react';

export function PayoutsPage() {
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  // Temp mocked data: Remove as part of: https://paytentlydev.atlassian.net/browse/COPS-884
  const data = {
    items: [
      {
        id: 'pyo_12345',
        status: 'complete',
        reference: 'ref',
        amount: 100,
        currency: CurrencyCodes.EUR,
        customer: {
          first_name: 'Jon',
          last_name: 'Doe',
          email: 'email@email.com',
        },
        method: {
          type: 'open_banking',
          open_banking: {
            account: {
              country: CountriesByCode.GBR,
              type: OpenBankingAccountType.iban,
              number: 'GB2550000000054400047903',
              bix: 'HBUKGB4B',
            },
          },
        },
        route: {
          id: '3',
          channel: 'Finshark',
          external_transaction_id: 'dac6fe23-02e8-40f3-bcde-917624751ab9',
        },
      },
      {
        id: 'pyo_12345',
        status: Status.abandoned,
        requested_on: '2024-12-13T12:53:49Z',
        reference: 'ref',
        amount: 235,
        currency: CurrencyCodes.EUR,
        customer: {
          first_name: 'Jane',
          last_name: 'Doe',
          email: 'email@email.com',
        },
        method: {
          type: 'open_banking',
          open_banking: {
            account: {
              country: CountriesByCode.GBR,
              type: OpenBankingAccountType.iban,
              number: 'GB2550000000054400047903',
              bix: 'HBUKGB4B',
            },
          },
        },
        route: {
          id: '3',
          channel: 'Finshark',
          external_transaction_id: 'dac6fe23-02e8-40f3-bcde-917624751ab9',
        },
      },
    ],
  };

  return (
    <Stack gap="lg" px="xl" pt="lg">
      <Group justify="space-between">
        <PageTitle title="Payouts" IconComponent={PayoutPageIcon} />
      </Group>

      <PayoutsTable data={data?.items ?? []} tableBodyRef={tableBodyRef} isLoading={false} />
    </Stack>
  );
}
