import { AddNewUserRequest, EditUserRequest } from "@paytently/types";
import { axios } from "./fetch";

export async function getUserList(entityId: string) {
  const response = await axios.get(`/auth/entities/${entityId}/users`);
  return response.data;
}

export async function getUserById(userId: string) {
  const response = await axios.get(`/auth/users/${userId}`);
  return response.data;
}

export async function suspendUser({ userId }: { userId: string }) {
  const response = await axios.delete(`/auth/users/${userId}`);
  return response.data;
}

export async function editUser({
  userId,
  user,
}: {
  userId: string;
  user: EditUserRequest;
}) {
  const response = await axios.put(`/auth/users/${userId}`, user);
  return response.data;
}

export async function addNewUser({ user }: { user: AddNewUserRequest }) {
  const response = await axios.post(`/auth/users`, user);

  return response.data;
}

export async function resetPassword({ userId }: { userId: string }) {
  const response = await axios.post(`/auth/users/${userId}/reset-password`);
  return response.data;
}
