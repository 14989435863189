import { ReactNode } from 'react';

import { ActionIcon, Divider, Group, Stack } from '@mantine/core';

import ArrowBack from '@material-symbols/svg-400/sharp/arrow_back_ios_new.svg?react';

import { Title } from '../../Typography';

export const TopAppBar = ({
  title,
  onBack,
  actionButtons,
}: {
  title: string;
  onBack?: () => void;
  actionButtons?: ReactNode;
}) => {
  return (
    <Stack gap={0} justify="center" h={56} w="100%">
      <Group flex="2">
        <ActionIcon w={24} h={24} onClick={onBack}>
          <ArrowBack fill="var(--mantine-color-neutral-3)" />
        </ActionIcon>

        <Title order={1} weight="medium">
          {title}
        </Title>

        {actionButtons && <Group ml="auto">{actionButtons}</Group>}
      </Group>
      <Divider m={0} />
    </Stack>
  );
};
