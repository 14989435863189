import { useQuery } from "@tanstack/react-query";
import { getUserList } from "../../api/authentication";
import { UserListResponse } from "@paytently/types";
const QUERY_KEY = "users";

export function useGetUserList(entityId: string) {
  const query = useQuery<UserListResponse>({
    queryKey: [QUERY_KEY, entityId],
    queryFn: () => getUserList(entityId),
  });
  return query;
}
