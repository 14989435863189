import { TransactionDetailsHeader, TransactionDetailSummary } from './components';

import { Box, Flex, ScrollArea } from '@mantine/core';

import { useGetPaymentById } from '@paytently/api';

import { TransactionQuickViewHeader } from '../TransactionQuickView/components';
import { useIsPaymentsQuickViewRoute } from '@#/hooks';

import styles from './TransactionDetails.module.css';

type TransactionQuickViewProps = {
  paymentId: string;
};

function TransactionDetails({ paymentId }: TransactionQuickViewProps) {
  const isPaymentsQuickView = useIsPaymentsQuickViewRoute();

  const { data } = useGetPaymentById(paymentId);

  return (
    <Box className={styles.paymentDetailsContainer}>
      {isPaymentsQuickView && <TransactionQuickViewHeader transactionId={paymentId} />}
      {data && <TransactionDetailsHeader paymentData={data} />}
      <ScrollArea
        classNames={{ root: styles.paymentDetailsScrollArea }}
        data-payments-view={isPaymentsQuickView ? 'quick-view' : 'full-view'}
      >
        <Flex px="xl" py="lg">
          {data && <TransactionDetailSummary data={data} />}
        </Flex>
      </ScrollArea>
    </Box>
  );
}

export default TransactionDetails;
