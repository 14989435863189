import { Section, SectionDataPoint } from './TransactionDetailDataPoints';
import { path, pathOr } from 'ramda';

import { Accordion, Divider, Stack } from '@mantine/core';

import { TransactionDetailDataPoint } from '../TransactionDetailDataPoint';
import { useIsInternal } from '@#/hooks';

interface Props {
  data: any;
  section: Section;
}

export function TransactionDetailSection({ data, section }: Props) {
  const userIsInternal = useIsInternal();

  const shouldHide = section.requires && !path(section.requires, data);
  if (shouldHide) return null;

  const userHasPermission = (datapoint: SectionDataPoint) =>
    !datapoint.permission ||
    (datapoint.permission === 'client' && !userIsInternal) ||
    (datapoint.permission === 'internal' && userIsInternal);

  const renderDataPointForEachItem = () => {
    if ('dataMap' in section) {
      const dataRoot = path(section.dataMap.list, data) as object[];

      return dataRoot.map((dataInstance, index) => (
        <Stack>
          {renderSectionData({ dataPoints: section.dataMap.data, dataRoot: dataInstance })}
          {index < dataRoot.length - 1 && <Divider my="md" />}
        </Stack>
      ));
    }
  };

  const renderSectionData = ({
    dataPoints,
    dataRoot,
  }: {
    dataPoints: SectionDataPoint[];
    dataRoot: Object;
  }) => {
    return dataPoints.map(
      (datapoint) =>
        datapoint.enabled &&
        userHasPermission(datapoint) && (
          <TransactionDetailDataPoint
            key={datapoint.label}
            label={datapoint.label}
            value={pathOr('-', datapoint.value, dataRoot)}
            type={datapoint.type}
            payment={data}
          />
        )
    );
  };

  const renderedDataPoints =
    'dataMap' in section
      ? renderDataPointForEachItem()
      : renderSectionData({ dataPoints: section.data, dataRoot: data });

  return (
    <Accordion.Item value={section.id}>
      <Accordion.Control>{section.label}</Accordion.Control>
      <Accordion.Panel>
        <Stack>{renderedDataPoints}</Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
