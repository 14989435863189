import { z } from 'zod';

import { Stack, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

export const formSchema = z.object({
  first_name: z.string().min(2, { message: 'First name should have at least 2 letters' }),
  last_name: z.string().min(2, { message: 'Last name should have at least 2 letters' }),
  email_address: z
    .string()
    .email({ message: 'Please enter your email address in the format: example@domain.com' }),
});

interface Props {
  onSubmit: (values: z.infer<typeof formSchema>) => void;
  form: UseFormReturnType<any>;
  isEmailDisabled?: boolean;
}

export function AddOrEditUserForm({ onSubmit, form, isEmailDisabled = false }: Props) {
  return (
    <form id="add-or-edit-user-form" onSubmit={form.onSubmit(onSubmit)}>
      <Stack gap="md">
        <TextInput
          label="First name"
          placeholder="Enter first name"
          key={form.key('first_name')}
          {...form.getInputProps('first_name')}
        />

        <TextInput
          label="Last name"
          placeholder="Enter last name"
          key={form.key('last_name')}
          {...form.getInputProps('last_name')}
        />

        <TextInput
          label="Email"
          placeholder="Enter email"
          key={form.key('email_address')}
          disabled={isEmailDisabled}
          {...form.getInputProps('email_address')}
        />
      </Stack>
    </form>
  );
}
