import { Permissions } from '@paytently/authentication';
import { PaymentResponse, Status } from '@paytently/types';
import { TextButton, useDisplayMode } from '@paytently/ui';

import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import CreditCardOffIcon from '@material-symbols/svg-400/rounded/credit_card_off.svg?react';
import UndoIcon from '@material-symbols/svg-400/rounded/undo.svg?react';

import { RequiresPermissions } from '@#/utils/RequiresPermission';

interface Props {
  paymentData: PaymentResponse;
}

export function PaymentActionButton({ paymentData }: Props) {
  const { isInMobileMode } = useDisplayMode();
  const { id: paymentId } = paymentData;

  const openRefundModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'refund',
      size: '512px',
      innerProps: { paymentId },
    });
  };

  const openVoidModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'void',
      size: '512px',
      innerProps: { paymentId },
    });
  };

  switch (paymentData.status) {
    case Status.captured:
      return (
        paymentData.amount_details.available_to_refund > 0 && (
          <RequiresPermissions checkPermission={Permissions['refund:request']}>
            <TextButton ml="auto" icon={<UndoIcon />} onClick={openRefundModal}>
              Refund
            </TextButton>
          </RequiresPermissions>
        )
      );

    case Status.authorized:
      return (
        <RequiresPermissions checkPermission={Permissions['void:request']}>
          <TextButton ml="auto" icon={<CreditCardOffIcon />} onClick={openVoidModal}>
            Void
          </TextButton>
        </RequiresPermissions>
      );
    default:
      return null;
  }
}
