import { useState } from 'react';

import { Button, Checkbox, Modal, Stack } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { Title, Text, ActionBar } from '@paytently/ui';

import ErrorIcon from '@material-symbols/svg-400/rounded/error.svg?react';

interface ExportReportTermsModalProps {
  confirmAction: () => void;
  setTermsCheckedPreference: (preference: string) => void;
}

export function ExportReportTermsModal({
  context,
  id,
  innerProps,
}: ContextModalProps<ExportReportTermsModalProps>) {
  const [exportReportTermsChecked, setExportReportTermsChecked] = useState(false);
  const { confirmAction, setTermsCheckedPreference } = innerProps;

  function handleExportReportTermsChecked(checked: boolean) {
    setTermsCheckedPreference(checked.toString());
    setExportReportTermsChecked(checked);
  }

  function closeModal() {
    context.closeModal(id);
  }

  function handleActionClick() {
    closeModal();
    confirmAction();
  }

  return (
    <Stack gap="md">
      <Stack align="center" gap="md">
        <ErrorIcon fill="var(--mantine-color-yellow-0)" />
        <Title order={2} weight="medium">
          Attention
        </Title>
      </Stack>

      <Text>
        This data in your exported report will be filtered according to the date range and filters
        you have applied.
      </Text>
      <Text>The report may take a few seconds to start downloading.</Text>
      <Checkbox
        checked={exportReportTermsChecked}
        onChange={(event) => handleExportReportTermsChecked(event.currentTarget.checked)}
        label="Don't show this message again"
      />
      <ActionBar
        pt="md"
        pb="lg"
        px={0}
        rightSection={
          <>
            <Button color="neutral.3" variant="subtle" size="md" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color="secondary.1"
              variant="subtle"
              size="md"
              onClick={() => handleActionClick()}
            >
              Export report
            </Button>
          </>
        }
      />
    </Stack>
  );
}
