import { useMutation } from "@tanstack/react-query";
import { voidPayment } from "../../api/payments";
import { VoidRequest } from "@paytently/types/src/VoidResponse";

export function useVoidPayment() {
  const mutation = useMutation({
    mutationFn: ({ id, reference }: VoidRequest) =>
      voidPayment({ id, reference }),
  });

  return mutation;
}
