import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { lazy as ReactLazy, Suspense } from 'react';

import { RouterContext } from '@paytently/types';

const TanStackRouterDevtools = ['production', 'staging'].includes(
  import.meta.env.VITE_APP_ENVIRONMENT
)
  ? () => null
  : ReactLazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  return (
    <>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools position="bottom-left" initialIsOpen={false} />
      </Suspense>
    </>
  );
}
