import { useMutation } from "@tanstack/react-query";
import { suspendUser } from "../../api/authentication";
import { SuspendUserRequest } from "@paytently/types";

export function useSuspendUser() {
  const query = useMutation({
    mutationFn: ({ userId }: SuspendUserRequest) => suspendUser({ userId }),
  });
  return query;
}
