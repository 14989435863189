import { RenderChildrenOrPlaceHolder } from './RenderChildrenOrPlaceHolder';
import { MRT_Cell, MRT_RowData } from 'mantine-react-table';

import { StatusType } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { TablePaymentStatus } from '@#/components/PaymentStatus/PaymentStatus';

export function StatusDataTableCell<T extends MRT_RowData>({ cell }: { cell: MRT_Cell<T> }) {
  const { isInMobileMode } = useDisplayMode();

  return (
    <RenderChildrenOrPlaceHolder<T> cell={cell}>
      <TablePaymentStatus status={cell.getValue<StatusType>()} iconOnly={isInMobileMode} />
    </RenderChildrenOrPlaceHolder>
  );
}
