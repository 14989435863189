import { AccessLevel, AccessLevelType } from '@paytently/types';

export type SupportedComponentTypes =
  | 'date'
  | 'scheme'
  | 'payment-status'
  | 'payment-reject-reason'
  | 'payment-reject-code'
  | 'payment-channel';

export type SectionDataPoint = {
  value: string[];
  label: string;
  type?: SupportedComponentTypes;
  permission?: AccessLevelType;
  enabled: boolean;
};

type SectionContents =
  | { data: SectionDataPoint[] }
  | { dataMap: { list: string[]; data: SectionDataPoint[] } }; // Used when section contains a list that needs to be mapped over

export type Section = {
  id: string;
  label: string;
  requires?: string[];
  permission?: AccessLevelType;
  order: 1 | 2;
} & SectionContents;

export const TransactionDetailDataPoints: Section[] = [
  {
    id: 'void-details',
    label: 'Void details',
    requires: ['voids'],
    order: 1,
    dataMap: {
      list: ['voids'],
      data: [
        { value: ['status'], label: 'Status', type: 'payment-status', enabled: true },
        { value: ['requested_on'], label: 'Date & time', type: 'date', enabled: true },
      ],
    },
  },
  {
    id: 'refund-details',
    label: 'Refund details',
    requires: ['refunds'],
    order: 1,
    dataMap: {
      list: ['refunds'],
      data: [
        { value: ['amount'], label: 'Amount', enabled: true },
        { value: ['requested_on'], label: 'Date & time', type: 'date', enabled: true },
        { value: ['status'], label: 'Status', type: 'payment-status', enabled: true },
      ],
    },
  },
  {
    id: 'payment-details',
    label: 'Payment details',
    order: 1,
    data: [
      {
        value: ['requested_on'],
        label: 'Date & Time',
        type: 'date',
        enabled: true,
      },
      {
        value: ['id'],
        label: 'Payment ID',
        enabled: true,
      },
      {
        value: ['reference'],
        label: 'Reference',
        enabled: true,
      },
      {
        value: ['method', 'type'],
        label: 'Payment method',
        enabled: true,
      },
      {
        value: ['status'],
        label: 'Status',
        type: 'payment-status',
        enabled: true,
      },
      {
        value: ['errors'],
        label: 'reason',
        type: 'payment-reject-reason',
        enabled: true,
      },
      {
        value: ['errors'],
        label: 'code',
        type: 'payment-reject-code',
        enabled: true,
      },
      {
        value: ['route', 'channel', 'name'],
        label: 'Channel',
        type: 'payment-channel',
        enabled: true,
      },
      {
        value: [''],
        label: 'Connector',
        permission: AccessLevel.internal,
        enabled: true,
      },
      {
        value: [''],
        label: 'Acquirer',
        permission: AccessLevel.internal,
        enabled: true,
      },
      {
        value: [''],
        label: 'MID',
        permission: AccessLevel.internal,
        enabled: true,
      },
      {
        value: ['route', 'id'],
        label: 'Route ID',
        enabled: true,
      },
      {
        value: ['route', 'external_transaction_id'],
        label: 'External ID',
        permission: AccessLevel.internal,
        enabled: true,
      },
      {
        value: ['metadata'],
        label: 'Metadata',
        enabled: false,
      },
      {
        value: ['currency'],
        label: 'Currency',
        enabled: true,
      },
      {
        value: ['amount'],
        label: 'Transaction amount',
        enabled: true,
      },
      {
        value: ['amount_details', 'total_captured'],
        label: 'Captured amount',
        enabled: true,
      },
      {
        value: ['3ds'],
        label: '3DS check',
        enabled: false, // Enable once 3DS data is returned from the API
      },
      {
        value: [''],
        label: 'Authorisation code',
        enabled: true,
      },
      {
        value: [''],
        label: 'Descriptor',
        enabled: true,
      },
    ],
  },
  {
    id: 'beneficiary-details',
    label: 'Beneficiary details',
    order: 1,
    data: [
      {
        value: [''],
        label: 'Entity',
        enabled: true,
        permission: AccessLevel.internal,
      },
      {
        value: [''],
        label: 'Entity ID',
        enabled: true,
      },
      {
        value: [''],
        label: 'Merchant',
        permission: AccessLevel.client,
        enabled: true,
      },
      {
        value: [''],
        label: 'Child Entity',
        permission: AccessLevel.internal,
        enabled: true,
      },
      {
        value: [''],
        label: 'Application ID',
        enabled: true,
      },
    ],
  },
  {
    id: 'customer-details',
    label: 'Customer details',
    order: 2,
    data: [
      {
        value: ['customer', 'id'],
        label: 'Customer ID',
        enabled: false,
      },
      {
        value: ['customer', 'first_name'],
        label: 'Customer name',
        enabled: true,
      },
      {
        value: ['customer', 'birth_date'],
        label: 'Birth date',
        type: 'date',
        enabled: true,
      },
      {
        value: ['customer', 'contact', 'email'],
        label: 'Email address',
        enabled: true,
      },
      {
        value: ['customer', 'contact', 'phone', 'number'],
        label: 'Phone number',
        enabled: true,
      },
      {
        value: ['billing_address', 'line_one'],
        label: 'Billing address',
        enabled: true,
      },
      {
        value: ['billing_address', 'postal_code'],
        label: 'Postcode',
        enabled: true,
      },
      {
        value: ['billing_address', 'country'],
        label: 'Country',
        enabled: true,
      },
      {
        value: ['billing_address', 'ip_address'],
        label: 'IP address',
        enabled: false,
      },
    ],
  },
  {
    id: 'card-details',
    label: 'Card details',
    order: 2,
    requires: ['method', 'card'],
    data: [
      {
        value: ['method', 'card', 'name'],
        label: 'Name on card',
        enabled: false,
      },
      {
        value: ['method', 'card', 'number'],
        label: 'Card number',
        enabled: true,
      },
      {
        value: ['method', 'scheme'],
        label: 'Scheme',
        type: 'scheme',
        enabled: true,
      },
      {
        value: ['method', 'card', 'issuer', 'name'],
        label: 'Issuing bank',
        enabled: true,
      },
      {
        value: ['method', 'card', 'issuer', 'country'],
        label: 'Issuing country',
        enabled: true,
      },
      {
        value: [''],
        label: 'Card type',
        enabled: true,
      },
      {
        value: [''],
        label: 'Card segment',
        enabled: true,
      },
    ],
  },
  {
    id: 'open-banking-details',
    label: 'Open banking details',
    order: 2,
    requires: ['method', 'open_banking', 'account'],
    data: [
      {
        value: ['method', 'open_banking', 'account', 'country'],
        label: 'Bank account Country',
        enabled: true,
      },
      {
        value: ['method', 'open_banking', 'account', 'number'],
        label: 'Bank account number',
        enabled: true,
      },
      {
        value: ['method', 'open_banking', 'account', 'bic'],
        label: 'BIC',
        enabled: true,
      },
      {
        value: ['method', 'open_banking', 'account', 'type'],
        label: 'Bank account type',
        enabled: true,
      },
    ],
  },
];
