import { useQuery } from "@tanstack/react-query";
import { getUserById } from "../../api/authentication";
import { UserResponse } from "@paytently/types";
const QUERY_KEY = "user";

export function useGetUserById(userId: string) {
  const query = useQuery<UserResponse>({
    queryKey: [QUERY_KEY, userId],
    queryFn: () => getUserById(userId),
  });
  return query;
}
