import { Group } from '@mantine/core';

import { PaymentError, PaymentResponse, SchemeType, Status, StatusType } from '@paytently/types';
import { Text } from '@paytently/ui';

import { CardSchemeIcon } from '@#/components/CardSchemeIcon/CardSchemeIcon';
import { DisplayWithPaymentStatus } from '@#/components/DisplayWithPaymentStatus';
import { DetailsPaymentStatus } from '@#/components/PaymentStatus/PaymentStatus';

import { SupportedComponentTypes } from '../TransactionDetail';
import { useFormattedDate } from '@#/hooks';

interface Props {
  readonly label: string;
  readonly value: string;
  readonly type?: SupportedComponentTypes;
  readonly payment: PaymentResponse;
}

export function TransactionDetailDataPoint({ label, value, type, payment }: Props) {
  switch (type) {
    case 'scheme':
      return (
        <Group grow wrap="nowrap" gap="md">
          <Text>{label}</Text>
          <span>
            <CardSchemeIcon scheme={value as SchemeType} />
          </span>
        </Group>
      );
    case 'date':
      const formattedDate = useFormattedDate({ date: value });
      return (
        <Group grow wrap="nowrap" gap="md">
          <Text>{label}</Text>
          <Text weight="normal">{formattedDate}</Text>
        </Group>
      );
    case 'payment-status':
      return (
        <Group grow wrap="nowrap" gap="md">
          <Text>{label}</Text>
          <DetailsPaymentStatus status={Status[value as StatusType]} />
        </Group>
      );
    case 'payment-reject-code':
      const { code } = value[0] as unknown as PaymentError;
      return (
        <DisplayWithPaymentStatus
          paymentStatus={payment.status}
          withStatuses={[Status.declined, Status.failed]}
        >
          <Group grow wrap="nowrap" gap="md">
            <Text>
              {payment.status == Status.declined ? 'Decline' : 'Fail'} {label}
            </Text>
            <Text weight="normal">{code}</Text>
          </Group>
        </DisplayWithPaymentStatus>
      );
    case 'payment-reject-reason':
      const { reason } = value[0] as unknown as PaymentError;
      return (
        <DisplayWithPaymentStatus
          paymentStatus={payment.status}
          withStatuses={[Status.declined, Status.failed]}
        >
          <Group grow wrap="nowrap" gap="md">
            <Text>
              {payment.status == Status.declined ? 'Decline' : 'Fail'} {label}
            </Text>
            <Text weight="normal">{reason}</Text>
          </Group>
        </DisplayWithPaymentStatus>
      );
    case 'payment-channel':
      return (
        <Group grow wrap="nowrap" gap="md">
          <Text>{label}</Text>
          <Text weight="normal">{value}</Text>
        </Group>
      );
    default:
      return (
        <Group grow wrap="nowrap" gap="md">
          <Text>{label}</Text>
          <Text weight="normal">{value}</Text>
        </Group>
      );
  }
}
