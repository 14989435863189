import { Box, Group } from '@mantine/core';
import { DatePickerValue } from '@mantine/dates';

import { DatePickerInput, SearchInput, SearchInputMenuOption } from '@paytently/ui';

export function SearchBar({
  initialSearchValue,
  initialSearchOption,
  onSearch,
  onDateChange,
  defaultDates,
}: {
  initialSearchValue: string | undefined;
  initialSearchOption: SearchInputMenuOption | undefined;
  onSearch: (params: { option: SearchInputMenuOption; value: string }) => void;
  onDateChange: (newDate: DatePickerValue<'range'>) => void;
  defaultDates: DatePickerValue<'range'>;
}) {
  const searchOptions = [
    { value: 'payment_id', label: 'Payment ID' },
    { value: 'reference', label: 'Reference' },
  ];

  return (
    <Group>
      <Box style={{ flexGrow: 2 }}>
        <SearchInput
          initialValue={initialSearchValue}
          initialOption={initialSearchOption}
          onSearch={onSearch}
          searchOptions={searchOptions}
          showOptions={true}
        />
      </Box>
      <DatePickerInput type="range" onChange={onDateChange} defaultDates={defaultDates} />
    </Group>
  );
}
