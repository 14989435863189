export const adminPermission = "paytently:*";

export enum Permissions {
  "dashboard:read" = "dashboard:read",
  "payments:read" = "payments:read",
  "refund:request" = "refund:request",
  "refund:read" = "refund:read",
  "void:request" = "void:request",
  "void:read" = "void:read",
  "usermanagement:create" = "usermanagement:create",
  "usermanagement:read" = "usermanagement:read",
  "usermanagement:edit" = "usermanagement:edit",
  "usermanagement:delete" = "usermanagement:delete",
  "payouts:read" = "payouts:read",
}
