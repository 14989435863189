import { modals } from './components';
import RouterWithAuthContext from './routerWithAuthContext';
import { datadogRum } from '@datadog/browser-rum';
import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';

import { ThemedMantineProvider } from '@paytently/ui';

import { SessionProvider } from '@#/contexts/SessionContext';

import './App.css';
import 'mantine-react-table/styles.css';

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'local') {
  datadogRum.init({
    applicationId: 'e69a0b7c-57bc-4dcc-9336-9329165f7efb',
    clientToken: 'pub087031b053587a4925d40f997bf1fabe',
    site: 'datadoghq.eu',
    service: 'client-portal',
    env: import.meta.env.VITE_APP_ENVIRONMENT,
    version: import.meta.env.VITE_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
  });
}

export default function App() {
  const queryClient = new QueryClient();
  const { origin } = window && window.location;

  return (
    // Wrap the app with third party context providers
    <ThemedMantineProvider>
      <KindeProvider
        clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
        domain={import.meta.env.VITE_KINDE_DOMAIN}
        redirectUri={origin}
        logoutUri={origin}
        audience={import.meta.env.VITE_KINDE_AUDIENCE}
      >
        <Notifications />
        <QueryClientProvider client={queryClient}>
          {/* Then with our own global state context providers*/}
          <ModalsProvider modals={modals}>
            <SessionProvider>
              {/* Finally render the page we're routed to*/}
              <RouterWithAuthContext />
            </SessionProvider>
          </ModalsProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </KindeProvider>
    </ThemedMantineProvider>
  );
}
