import { ReactElement } from 'react';

import { Tabs } from '@mantine/core';

import { Text } from '@paytently/ui';

interface Props {
  readonly details: ReactElement;
}

export function TransactionDetailTabs({ details }: Props) {
  return (
    <Tabs defaultValue="details">
      <Tabs.List>
        <Tabs.Tab value="details">
          <Text weight="medium">Details</Text>
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="details" pt="lg">
        {details}
      </Tabs.Panel>
    </Tabs>
  );
}
