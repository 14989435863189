import { createFileRoute, redirect } from '@tanstack/react-router';

import { setAccessToken } from '@paytently/authentication';

import { MainLayout } from '@#/layouts';
import { routeGuard } from '@#/utils';

export const Route = createFileRoute('/_authenticated')({
  component: RouteComponent,
  async beforeLoad(ctx) {
    const { context: authContext, matches } = ctx;

    if (authContext.isAuthenticated) {
      const accessToken = await authContext.getToken();

      if (!accessToken) {
        throw redirect({ to: '/login' });
      }

      await setAccessToken(accessToken);
    }

    routeGuard({ fullPath: matches[matches.length - 1].fullPath, authContext });
  },
});

function RouteComponent() {
  return <MainLayout />;
}
