export const Status = {
  pending: "pending",
  authorized: "authorized",
  captured: "captured",
  abandoned: "abandoned",
  voided: "voided",
  failed: "failed",
  completed: "completed",
  declined: "declined",
  complete: "complete",
} as const;

export type StatusType = (typeof Status)[keyof typeof Status];
