import { createContext, useContext, useReducer } from 'react';

// All properties that can be tracked on this slice of state
type State = { testAccount: boolean };

// All actions that can be performed to modify the state
type Action = { type: 'setTestAccount'; payload: boolean };

type Dispatch = (action: Action) => void;
type SessionProviderProps = { children: React.ReactNode };

export const SessionContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
);

function sessionReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setTestAccount': {
      return { testAccount: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function SessionProvider({ children }: SessionProviderProps) {
  const [state, dispatch] = useReducer(sessionReducer, { testAccount: false });
  const value = { state, dispatch };
  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
}
