import { useState } from 'react';

import {
  Group,
  MultiSelect,
  RangeSlider,
  Stack,
  TextInput,
  Switch,
  Checkbox,
  Flex,
  Chip,
} from '@mantine/core';

import { SchemeType } from '@paytently/types';
import { FilterChip, Text, Title } from '@paytently/ui';

import { CardSchemeIcon } from '@#/components/CardSchemeIcon/CardSchemeIcon';
import {
  AppliedFilters,
  FilterConfigMap,
  FilterOptionType,
  FilterTypes,
  MultiSelectDataType,
} from '@#/components/Filters/FilterTypes';

import MultiSelectClosedIcon from '@material-symbols/svg-400/rounded/keyboard_arrow_down.svg?react';
import MultiSelectOpenIcon from '@material-symbols/svg-400/rounded/keyboard_arrow_up.svg?react';

type TempFiltersUpdateFunc = React.Dispatch<React.SetStateAction<AppliedFilters>>;

const handleMultiSelectChange = (
  setTempFilters: TempFiltersUpdateFunc,
  label: string,
  values: MultiSelectDataType
) => {
  setTempFilters((previousState: AppliedFilters) => {
    return {
      ...previousState,
      [label]: {
        type: FilterTypes.multiSelect,
        value: values,
      },
    };
  });
};

const handleSchemeSelectChange = (
  setTempFilters: TempFiltersUpdateFunc,
  label: string,
  values: MultiSelectDataType
) => {
  setTempFilters((previousState: AppliedFilters) => {
    return {
      ...previousState,
      [label]: {
        type: FilterTypes.schemeSelection,
        value: values,
      },
    };
  });
};

const handleRangeChange = (
  setTempFilters: TempFiltersUpdateFunc,
  label: string,
  min?: number,
  max?: number
) => {
  setTempFilters((previousState: AppliedFilters) => ({
    ...previousState,
    [label]: {
      type: FilterTypes.range,
      value: { min, max },
    },
  }));
};

const handleSingleValueChange = (
  setTempFilters: TempFiltersUpdateFunc,
  filterType: FilterTypes.singleSelect | FilterTypes.chips,
  label: string,
  value: string
) => {
  setTempFilters((previousState: AppliedFilters) => ({
    ...previousState,
    [label]: { type: filterType, value },
  }));
};

const handleBooleanValueChange = (
  setTempFilters: TempFiltersUpdateFunc,
  filterType: FilterTypes.boolean,
  label: string,
  value: boolean
) => {
  setTempFilters((previousState: AppliedFilters) => ({
    ...previousState,
    [label]: {
      type: filterType,
      value,
    },
  }));
};

const handleInputValueChange = (
  setTempFilters: TempFiltersUpdateFunc,
  filterType: FilterTypes.input,
  label: string,
  value: string
) => {
  setTempFilters((previousState: AppliedFilters) => ({
    ...previousState,
    [label]: {
      type: filterType,
      value,
    },
  }));
};

export function FilterOption({
  label,
  description,
  type,
  props,
  tempFilters,
  setTempFilters,
}: FilterOptionType & { tempFilters: AppliedFilters; setTempFilters: TempFiltersUpdateFunc }) {
  switch (type) {
    case 'range': {
      const value = (tempFilters[label]?.value as FilterConfigMap['range']['value']) || {};

      return (
        <Stack gap={16}>
          <div>
            <Title order={3} weight="medium">
              {label}
            </Title>
            <Text c="neutral.3">{description}</Text>
          </div>
          <Group wrap="nowrap">
            <TextInput
              label="Min"
              type="number"
              defaultValue={props.min}
              value={value.min}
              onChange={(e) =>
                handleRangeChange(
                  setTempFilters,
                  label,
                  Number(e.target.value) || props.min,
                  value.max
                )
              }
            />
            <Text weight="medium" mt="lg">
              -
            </Text>
            <TextInput
              label="Max"
              type="number"
              defaultValue={props.max}
              value={value.max}
              onChange={(e) =>
                handleRangeChange(
                  setTempFilters,
                  label,
                  value.min,
                  Number(e.target.value) || props.min
                )
              }
            />
          </Group>
          <RangeSlider
            m="auto"
            w="100%"
            value={[value.min || props.min, value.max || props.min]}
            onChange={(value) => handleRangeChange(setTempFilters, label, value[0], value[1])}
          />
        </Stack>
      );
    }

    case 'multiSelect': {
      const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
      const multiSelectDataList =
        (tempFilters[label]?.value as FilterConfigMap['multiSelect']['value']) || [];
      const multiSelectFlattenedDataList = multiSelectDataList.map(
        (multiSelectDataOption) => multiSelectDataOption.value
      );

      return (
        <MultiSelect
          onChange={(value) => {
            const list = value.map((val) =>
              props.data.find((i) => i.value === val)
            ) as MultiSelectDataType;
            handleMultiSelectChange(setTempFilters, label, list);
          }}
          value={multiSelectFlattenedDataList}
          placeholder={multiSelectFlattenedDataList.length ? '' : 'Start typing or select'}
          searchable
          label={label}
          description={description}
          data={props.data}
          onDropdownOpen={() => setIsMultiSelectOpen(true)}
          onDropdownClose={() => setIsMultiSelectOpen(false)}
          rightSection={isMultiSelectOpen ? <MultiSelectOpenIcon /> : <MultiSelectClosedIcon />}
        />
      );
    }

    case 'chips': {
      const value = (tempFilters[label]?.value as FilterConfigMap['chips']['value']) || '';
      return (
        <Stack gap="sm">
          <Text weight="medium">{label}</Text>
          <Flex gap="xs" wrap="wrap">
            <Chip.Group
              value={value}
              multiple={false}
              onChange={(value) =>
                handleSingleValueChange(setTempFilters, FilterTypes.chips, label, value)
              }
            >
              {props.data.map((option) => {
                return (
                  <FilterChip key={option.value} value={option.value}>
                    {option.label}
                  </FilterChip>
                );
              })}
            </Chip.Group>
          </Flex>
        </Stack>
      );
    }

    case 'boolean': {
      const value = (tempFilters[label]?.value as FilterConfigMap['boolean']['value']) || false;

      return (
        <Group justify="space-between">
          <Text weight="medium">{label}</Text>
          <Switch
            checked={value}
            onChange={(event) =>
              handleBooleanValueChange(
                setTempFilters,
                FilterTypes.boolean,
                label,
                event.target.checked
              )
            }
          />
        </Group>
      );
    }

    case 'schemeSelection': {
      const schemeSelectDataList =
        (tempFilters[label]?.value as FilterConfigMap['schemeSelection']['value']) || [];
      const schemeSelectFlattenedDataList = schemeSelectDataList.map(
        (schemeSelectDataOption) => schemeSelectDataOption.value
      );

      function SchemeIcon({ scheme }: { scheme: SchemeType }) {
        return (
          <Group gap="sm" px="md">
            <CardSchemeIcon scheme={scheme} />
            <Title order={3}>{scheme}</Title>
          </Group>
        );
      }

      return (
        <Stack gap="xs">
          <Text weight="medium">{label}</Text>
          <Checkbox.Group
            value={schemeSelectFlattenedDataList}
            onChange={(value) => {
              const list = value.map((val) =>
                props.data.find((i) => i.value === val)
              ) as MultiSelectDataType;
              handleSchemeSelectChange(setTempFilters, label, list);
            }}
          >
            <Stack gap="lg" w="100%">
              {props.data.map((item) => (
                <Checkbox
                  key={item.value}
                  value={item.value}
                  styles={{ body: { justifyContent: 'space-between' } }}
                  labelPosition="left"
                  label={<SchemeIcon scheme={item.value as SchemeType} />}
                />
              ))}
            </Stack>
          </Checkbox.Group>
        </Stack>
      );
    }

    case 'input': {
      const value = (tempFilters[label]?.value as FilterConfigMap['input']['value']) || [];

      return (
        <TextInput
          label={label}
          placeholder="Enter a value"
          onChange={(event) =>
            handleInputValueChange(setTempFilters, FilterTypes.input, label, event.target.value)
          }
          value={value}
        />
      );
    }
  }
}
