import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export type MediaQueryValueProps<T> = {
  base: T;
  xs?: T;
  sm?: T;
  md?: T;
  lg?: T;
  xl?: T;
};

export const useMediaQueryValue = <T,>(props: MediaQueryValueProps<T>): T => {
  const theme = useMantineTheme();

  const isXL = useMediaQuery(`(min-width: ${theme.breakpoints.xl.toString()})`, undefined, {
    getInitialValueInEffect: false,
  });
  const isLG = useMediaQuery(`(min-width: ${theme.breakpoints.lg.toString()})`, undefined, {
    getInitialValueInEffect: false,
  });
  const isMD = useMediaQuery(`(min-width: ${theme.breakpoints.md.toString()})`, undefined, {
    getInitialValueInEffect: false,
  });
  const isSM = useMediaQuery(`(min-width: ${theme.breakpoints.sm.toString()})`, undefined, {
    getInitialValueInEffect: false,
  });
  const isXS = useMediaQuery(`(min-width: ${theme.breakpoints.xs.toString()})`, undefined, {
    getInitialValueInEffect: false,
  });

  if (isXL && props.xl !== undefined) {
    return props.xl;
  }
  if (isLG && props.lg !== undefined) {
    return props.lg;
  }
  if (isMD && props.md !== undefined) {
    return props.md;
  }
  if (isSM && props.sm !== undefined) {
    return props.sm;
  }
  if (isXS && props.xs !== undefined) {
    return props.xs;
  }

  return props.base;
};

export const displayModes = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
} as const;
export type DisplayModes = (typeof displayModes)[keyof typeof displayModes];

export const useDisplayMode = () => {
  const displayMode = useMediaQueryValue({
    base: displayModes.DESKTOP,
    xs: displayModes.MOBILE,
    sm: displayModes.TABLET,
    lg: displayModes.DESKTOP,
  });

  return {
    displayMode,
    isInTabletMode: isInDisplayMode(displayMode, [displayModes.TABLET]),
    isInDesktopMode: isInDisplayMode(displayMode, [displayModes.DESKTOP]),
    isInMobileMode: isInDisplayMode(displayMode, [displayModes.MOBILE]),
  };
};

export const isInDisplayMode = (mode: DisplayModes, modesToCheck: DisplayModes[]): boolean =>
  modesToCheck.includes(mode);
