import React from 'react';

import { Group, Stack } from '@mantine/core';

import { PaymentResponse } from '@paytently/types';
import { Text } from '@paytently/ui';

import { MetricsPaymentStatus } from '@#/components/PaymentStatus/PaymentStatus';

import styles from './PaymentMetrics.module.css';

interface PaymentMetricProps {
  readonly title: string;
  readonly children: React.ReactNode;
}
function PaymentMetric({ title, children }: PaymentMetricProps) {
  return (
    <Stack align="center" justify="top" gap="xxs" flex={1} ta="center">
      <Text size="xs" c="neutral.3">
        {title}
      </Text>
      <Text size="sm" weight="medium" c="neutral.4">
        {children}
      </Text>
    </Stack>
  );
}

export const PaymentMetrics = ({ data, date }: { data: PaymentResponse; date: string }) => {
  return (
    <Group
      gap="xs"
      px="md"
      py="sm"
      bg="tertiary.3"
      bd="1px solid state.3"
      align="top"
      className={styles.summaryGroup}
    >
      <PaymentMetric title="Status">
        <MetricsPaymentStatus status={data.status} />
      </PaymentMetric>
      <PaymentMetric title="Channel">{data.route?.channel?.name ?? '-'}</PaymentMetric>
      <PaymentMetric title="Date">{date}</PaymentMetric>
    </Group>
  );
};
