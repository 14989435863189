/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as LoginLoginImport } from './routes/_login/login'
import { Route as AuthenticatedUsermanagementImport } from './routes/_authenticated/user_management'
import { Route as AuthenticatedPayoutsImport } from './routes/_authenticated/payouts'
import { Route as AuthenticatedPaymentsImport } from './routes/_authenticated/payments'
import { Route as AuthenticatedPaymentsPaymentIdImport } from './routes/_authenticated/payments/$paymentId'
import { Route as AuthenticatedDetailsPaymentIdImport } from './routes/_authenticated/details/$paymentId'

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const LoginLoginRoute = LoginLoginImport.update({
  id: '/_login/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedUsermanagementRoute =
  AuthenticatedUsermanagementImport.update({
    id: '/user_management',
    path: '/user_management',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedPayoutsRoute = AuthenticatedPayoutsImport.update({
  id: '/payouts',
  path: '/payouts',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPaymentsRoute = AuthenticatedPaymentsImport.update({
  id: '/payments',
  path: '/payments',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPaymentsPaymentIdRoute =
  AuthenticatedPaymentsPaymentIdImport.update({
    id: '/$paymentId',
    path: '/$paymentId',
    getParentRoute: () => AuthenticatedPaymentsRoute,
  } as any)

const AuthenticatedDetailsPaymentIdRoute =
  AuthenticatedDetailsPaymentIdImport.update({
    id: '/details/$paymentId',
    path: '/details/$paymentId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/payments': {
      id: '/_authenticated/payments'
      path: '/payments'
      fullPath: '/payments'
      preLoaderRoute: typeof AuthenticatedPaymentsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/payouts': {
      id: '/_authenticated/payouts'
      path: '/payouts'
      fullPath: '/payouts'
      preLoaderRoute: typeof AuthenticatedPayoutsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/user_management': {
      id: '/_authenticated/user_management'
      path: '/user_management'
      fullPath: '/user_management'
      preLoaderRoute: typeof AuthenticatedUsermanagementImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_login/login': {
      id: '/_login/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLoginImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/details/$paymentId': {
      id: '/_authenticated/details/$paymentId'
      path: '/details/$paymentId'
      fullPath: '/details/$paymentId'
      preLoaderRoute: typeof AuthenticatedDetailsPaymentIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/payments/$paymentId': {
      id: '/_authenticated/payments/$paymentId'
      path: '/$paymentId'
      fullPath: '/payments/$paymentId'
      preLoaderRoute: typeof AuthenticatedPaymentsPaymentIdImport
      parentRoute: typeof AuthenticatedPaymentsImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedPaymentsRouteChildren {
  AuthenticatedPaymentsPaymentIdRoute: typeof AuthenticatedPaymentsPaymentIdRoute
}

const AuthenticatedPaymentsRouteChildren: AuthenticatedPaymentsRouteChildren = {
  AuthenticatedPaymentsPaymentIdRoute: AuthenticatedPaymentsPaymentIdRoute,
}

const AuthenticatedPaymentsRouteWithChildren =
  AuthenticatedPaymentsRoute._addFileChildren(
    AuthenticatedPaymentsRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedPaymentsRoute: typeof AuthenticatedPaymentsRouteWithChildren
  AuthenticatedPayoutsRoute: typeof AuthenticatedPayoutsRoute
  AuthenticatedUsermanagementRoute: typeof AuthenticatedUsermanagementRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedDetailsPaymentIdRoute: typeof AuthenticatedDetailsPaymentIdRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedPaymentsRoute: AuthenticatedPaymentsRouteWithChildren,
  AuthenticatedPayoutsRoute: AuthenticatedPayoutsRoute,
  AuthenticatedUsermanagementRoute: AuthenticatedUsermanagementRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedDetailsPaymentIdRoute: AuthenticatedDetailsPaymentIdRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedRouteWithChildren
  '/payments': typeof AuthenticatedPaymentsRouteWithChildren
  '/payouts': typeof AuthenticatedPayoutsRoute
  '/user_management': typeof AuthenticatedUsermanagementRoute
  '/login': typeof LoginLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/details/$paymentId': typeof AuthenticatedDetailsPaymentIdRoute
  '/payments/$paymentId': typeof AuthenticatedPaymentsPaymentIdRoute
}

export interface FileRoutesByTo {
  '/payments': typeof AuthenticatedPaymentsRouteWithChildren
  '/payouts': typeof AuthenticatedPayoutsRoute
  '/user_management': typeof AuthenticatedUsermanagementRoute
  '/login': typeof LoginLoginRoute
  '/': typeof AuthenticatedIndexRoute
  '/details/$paymentId': typeof AuthenticatedDetailsPaymentIdRoute
  '/payments/$paymentId': typeof AuthenticatedPaymentsPaymentIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_authenticated/payments': typeof AuthenticatedPaymentsRouteWithChildren
  '/_authenticated/payouts': typeof AuthenticatedPayoutsRoute
  '/_authenticated/user_management': typeof AuthenticatedUsermanagementRoute
  '/_login/login': typeof LoginLoginRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/details/$paymentId': typeof AuthenticatedDetailsPaymentIdRoute
  '/_authenticated/payments/$paymentId': typeof AuthenticatedPaymentsPaymentIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/payments'
    | '/payouts'
    | '/user_management'
    | '/login'
    | '/'
    | '/details/$paymentId'
    | '/payments/$paymentId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/payments'
    | '/payouts'
    | '/user_management'
    | '/login'
    | '/'
    | '/details/$paymentId'
    | '/payments/$paymentId'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_authenticated/payments'
    | '/_authenticated/payouts'
    | '/_authenticated/user_management'
    | '/_login/login'
    | '/_authenticated/'
    | '/_authenticated/details/$paymentId'
    | '/_authenticated/payments/$paymentId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  LoginLoginRoute: typeof LoginLoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  LoginLoginRoute: LoginLoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_login/login"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/payments",
        "/_authenticated/payouts",
        "/_authenticated/user_management",
        "/_authenticated/",
        "/_authenticated/details/$paymentId"
      ]
    },
    "/_authenticated/payments": {
      "filePath": "_authenticated/payments.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/payments/$paymentId"
      ]
    },
    "/_authenticated/payouts": {
      "filePath": "_authenticated/payouts.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/user_management": {
      "filePath": "_authenticated/user_management.tsx",
      "parent": "/_authenticated"
    },
    "/_login/login": {
      "filePath": "_login/login.tsx"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/details/$paymentId": {
      "filePath": "_authenticated/details/$paymentId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/payments/$paymentId": {
      "filePath": "_authenticated/payments/$paymentId.tsx",
      "parent": "/_authenticated/payments"
    }
  }
}
ROUTE_MANIFEST_END */
