import { useSortable, defaultAnimateLayoutChanges, defaultNewIndexGetter } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MRT_CellValue, MRT_Column, MRT_RowData, MRT_TableInstance } from 'mantine-react-table';
import { type Dispatch, type SetStateAction, useRef } from 'react';

import { ActionIcon, Box, Checkbox, Menu, Tooltip } from '@mantine/core';
import { useMergedRef } from '@mantine/hooks';

import DragIndicator from '@material-symbols/svg-400/rounded/drag_indicator-fill.svg?react';

import styles from './styles.module.css';

interface Props<TData extends MRT_RowData, TValue = MRT_CellValue> {
  allColumns: MRT_Column<TData>[];
  column: MRT_Column<TData, TValue>;
  hoveredColumn: MRT_Column<TData> | null;
  setHoveredColumn: Dispatch<SetStateAction<MRT_Column<TData> | null>>;
  table: MRT_TableInstance<TData>;
}

export const ReorderPanelItems = <TData extends MRT_RowData>({
  allColumns,
  column,
  hoveredColumn,
  setHoveredColumn,
  table,
}: Props<TData>) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({
      id: column.id,
      animateLayoutChanges: defaultAnimateLayoutChanges,
      disabled: false,
      getNewIndex: defaultNewIndexGetter,
    });

  const {
    options: { localization },
  } = table;

  const { columnDef } = column;
  const { columnDefType } = columnDef;

  const switchChecked =
    (columnDefType !== 'group' && column.getIsVisible()) ||
    (columnDefType === 'group' && column.getLeafColumns().some((col) => col.getIsVisible()));

  const handleToggleColumnHidden = (column: MRT_Column<TData>) => {
    if (columnDefType === 'group') {
      column?.columns?.forEach?.((childColumn: MRT_Column<TData>) => {
        childColumn.toggleVisibility(!switchChecked);
      });
    } else {
      column.toggleVisibility();
    }
  };

  const menuItemRef = useRef<HTMLElement>(null);
  const combinedRef = useMergedRef(menuItemRef, setNodeRef, setActivatorNodeRef);

  if (!columnDef.header || columnDef.visibleInShowHideMenu === false) {
    return null;
  }

  const draggableProps =
    columnDef.enableColumnOrdering !== false
      ? {
          style: {
            transform: CSS.Transform.toString(transform),
            transition,
          },
          ...attributes,
        }
      : {};

  return (
    <>
      <Menu.Item ref={combinedRef} {...draggableProps}>
        <Box className={styles.menu}>
          <ActionIcon
            component="div"
            data-vaul-no-drag
            color="neutral.3"
            size="xs"
            variant="transparent"
            {...listeners}
          >
            <DragIndicator />
          </ActionIcon>
          <Tooltip label={localization.toggleVisibility} openDelay={1000} withinPortal>
            <Checkbox
              checked={switchChecked}
              className={styles.switch}
              label={columnDef.header}
              labelPosition="left"
              onChange={() => handleToggleColumnHidden(column)}
            />
          </Tooltip>
        </Box>
      </Menu.Item>
      {column.columns?.map((c: MRT_Column<TData>, i) => (
        <ReorderPanelItems
          allColumns={allColumns}
          column={c}
          hoveredColumn={hoveredColumn}
          key={`${i}-${c.id}`}
          setHoveredColumn={setHoveredColumn}
          table={table}
        />
      ))}
    </>
  );
};
