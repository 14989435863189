import { MRT_Cell } from 'mantine-react-table';
import { ReactNode } from 'react';

import { Skeleton } from '@mantine/core';

export function RenderChildrenOrPlaceHolder<T>({
  cell,
  children,
}: {
  cell: MRT_Cell<T>;
  children: ReactNode;
}) {
  return cell.getValue() === undefined ? <Skeleton width="100%">-</Skeleton> : <>{children}</>;
}
