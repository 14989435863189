import { UserResponse } from '@paytently/types';
import { useDisplayMode } from '@paytently/ui';

import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

export function useEditUser() {
  const { isInMobileMode } = useDisplayMode();

  const openEditUserModal = ({ userDetails }: { userDetails: UserResponse }) => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'editUser',
      size: '512px',
      innerProps: { userDetails },
    });
  };

  return {
    openEditUserModal,
  };
}
