import { useRef } from 'react';

import { Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useSearchPayments } from '@paytently/api';
import { PageTitle, Text } from '@paytently/ui';

import { AppliedFiltersDisplay } from '@#/components/Filters/AppliedFiltersDisplay';
import { FiltersSideBar } from '@#/components/Filters/FiltersSideBar';

import CreditCard from '@material-symbols/svg-400/rounded/credit_card.svg?react';
import FilterAlt from '@material-symbols/svg-400/rounded/filter_alt.svg?react';

import { ExportReport } from '@#/components';
import { useInfiniteScroll } from '@#/hooks/useInfiniteScroll';
import { paymentFilters } from '@#/pages/Payments/PaymentFilters';
import { PaymentsTable } from '@#/pages/Payments/PaymentsTable';
import { SearchBar } from '@#/pages/Payments/SearchBar';
import { usePaymentsFiltering } from '@#/pages/Payments/hooks';

export function Payments() {
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  const {
    appliedFilters,
    setAppliedFilters,
    searchBarFilter,
    setSearchBarFilter,
    dateRange,
    setDateRange,
    combinedSearchFilters,
  } = usePaymentsFiltering();

  const [filtersOpened, { toggle: toggleFilters, close: closeFilters }] = useDisclosure();

  const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useSearchPayments({
    pagination: { page: 0, size: 50 },
    filters: combinedSearchFilters,
  });

  useInfiniteScroll({
    observerTarget: tableBodyRef,
    pages: data?.pages || [],
    pagination: data?.pagination,
    isFetchingNextPage,
    fetchNextPage,
  });

  return (
    <>
      <FiltersSideBar
        opened={filtersOpened}
        close={closeFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        filterOptions={paymentFilters}
      />
      <Stack gap="lg" px="xl" pt="lg">
        <Group justify="space-between">
          <PageTitle title="Payments" IconComponent={CreditCard} />
          <ExportReport filters={combinedSearchFilters} />
        </Group>

        <SearchBar
          initialSearchValue={searchBarFilter?.value}
          initialSearchOption={searchBarFilter?.option}
          onDateChange={setDateRange}
          onSearch={(filter) => setSearchBarFilter(filter)}
          defaultDates={dateRange}
        />
        <Stack gap="xs">
          <Group justify="space-between">
            <Text weight="medium">{data?.pagination.total_hits} items</Text>
            <Button
              onClick={toggleFilters}
              size="md"
              variant="subtle"
              color="var(--mantine-color-neutral-3)"
              leftSection={<FilterAlt />}
            >
              Filters
            </Button>
          </Group>
          <AppliedFiltersDisplay
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
          <PaymentsTable
            data={data?.items ?? []}
            tableBodyRef={tableBodyRef}
            isLoading={isFetching}
          />
        </Stack>
      </Stack>
    </>
  );
}
