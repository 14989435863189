import Axios from "axios";

import { getAccessToken } from "@paytently/authentication";

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_ENDPOINT,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

// Intercept each network request and attach the authorization header
axios.interceptors.request.use(
  async (config) => {
    const accessToken = await getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
