import { MRT_Cell, MRT_RowData } from 'mantine-react-table';

import { RenderChildrenOrPlaceHolder } from '@#/components';

export function PaymentMethodCell<T extends MRT_RowData>({ cell }: { cell: MRT_Cell<T> }) {
  const cellValue = cell.getValue<T['method']['type']>();

  const text = cellValue === 'card' ? 'Card' : cellValue === 'open_banking' ? 'Open banking' : '-';

  return <RenderChildrenOrPlaceHolder cell={cell}>{text}</RenderChildrenOrPlaceHolder>;
}
