import { Link, ReactNode } from '@tanstack/react-router';

import { Tooltip, Transition } from '@mantine/core';

import { Text } from '@paytently/ui';

import { NavigationLinks } from '@#/NavigationLinks';
import { useAuthContext } from '@#/hooks';
import { routeGuard } from '@#/utils';

import styles from './Navigation.module.css';

const NavbarTooltip = ({
  label,
  disabled,
  children,
}: {
  label: string;
  disabled: boolean;
  children: ReactNode;
}) => (
  <Tooltip
    radius="xl"
    withArrow={false}
    events={{ hover: true, focus: true, touch: false }}
    position="right"
    label={<Text c="tertiary.0">{label}</Text>}
    disabled={disabled}
    color="secondary.1"
    key={label}
  >
    {children}
  </Tooltip>
);

interface Props {
  opened: boolean;
}

export function Navigation({ opened }: Props) {
  const authContext = useAuthContext();
  return NavigationLinks.map((item) => {
    const hasAccessToRoute = routeGuard({
      fullPath: item.path,
      authContext,
      shouldThrowAuthErrors: false,
    });

    if (!hasAccessToRoute) return null;

    return (
      <NavbarTooltip label={item.label} disabled={opened} key={item.label}>
        <Link className={styles.navigationLink} to={item.path}>
          {item.icon}
          <Transition mounted={opened}>
            {(transitionStyles) => <span style={transitionStyles}>{item.label}</span>}
          </Transition>
        </Link>
      </NavbarTooltip>
    );
  });
}

export default Navigation;
