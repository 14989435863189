/**
 * ISO3 Country Codes and Countries
 * Source: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
 */

export enum CountriesByCode {
  ABW = "Aruba",
  AFG = "Afghanistan",
  AGO = "Angola",
  AIA = "Anguilla",
  ALA = "Åland Islands",
  ALB = "Albania",
  AND = "Andorra",
  ARE = "United Arab Emirates",
  ARG = "Argentina",
  ARM = "Armenia",
  ASM = "American Samoa",
  ATA = "Antarctica",
  ATF = "French Southern and Antarctic Lands",
  ATG = "Antigua and Barbuda",
  AUS = "Australia",
  AUT = "Austria",
  AZE = "Azerbaijan",
  BDI = "Burundi",
  BEL = "Belgium",
  BEN = "Benin",
  BES = "Caribbean Netherlands",
  BFA = "Burkina Faso",
  BGD = "Bangladesh",
  BGR = "Bulgaria",
  BHR = "Bahrain",
  BHS = "The Bahamas",
  BIH = "Bosnia and Herzegovina",
  BLM = "Saint Barthélemy",
  BLR = "Belarus",
  BLZ = "Belize",
  BMU = "Bermuda",
  BOL = "Bolivia",
  BRA = "Brazil",
  BRB = "Barbados",
  BRN = "Brunei",
  BTN = "Bhutan",
  BVT = "Bouvet Island",
  BWA = "Botswana",
  CAF = "Central African Republic",
  CAN = "Canada",
  CCK = "Cocos (Keeling) Islands",
  CHE = "Switzerland",
  CHL = "Chile",
  CHN = "China",
  CIV = "Ivory Coast",
  CMR = "Cameroon",
  COD = "Democratic Republic of the Congo",
  COG = "Republic of the Congo",
  COK = "Cook Islands",
  COL = "Colombia",
  COM = "Comoros",
  CPV = "Cabo Verde",
  CRI = "Costa Rica",
  CUB = "Cuba",
  CUW = "Curaçao",
  CXR = "Christmas Island",
  CYM = "Cayman Islands",
  CYP = "Cyprus",
  CZE = "Czechia",
  DEU = "Germany",
  DJI = "Djibouti",
  DMA = "Dominica",
  DNK = "Denmark",
  DOM = "Dominican Republic",
  DZA = "Algeria",
  ECU = "Ecuador",
  EGY = "Egypt",
  ERI = "Eritrea",
  ESH = "Western Sahara",
  ESP = "Spain",
  EST = "Estonia",
  ETH = "Ethiopia",
  FIN = "Finland",
  FJI = "Fiji",
  FLK = "Falkland Island",
  FRA = "France",
  FRO = "Faroe Islands",
  FSM = "Federated States of Micronesi",
  GAB = "Gabon",
  GBR = "United Kingdom",
  GEO = "Georgia (country",
  GGY = "Bailiwick of Guernse",
  GHA = "Ghana",
  GIB = "Gibraltar",
  GIN = "Guinea",
  GLP = "Guadeloupe",
  GMB = "The Gambi",
  GNB = "Guinea-Bissau",
  GNQ = "Equatorial Guinea",
  GRC = "Greece",
  GRD = "Grenada",
  GRL = "Greenland",
  GTM = "Guatemala",
  GUF = "French Guiana",
  GUM = "Guam",
  GUY = "Guyana",
  HKG = "Hong Kong",
  HMD = "Heard Island and McDonald Islands",
  HND = "Honduras",
  HRV = "Croatia",
  HTI = "Haiti",
  HUN = "Hungary",
  IDN = "Indonesia",
  IMN = "Isle of Man",
  IND = "India",
  IOT = "British Indian Ocean Territory",
  IRL = "Republic of Irelan",
  IRN = "Iran, Islamic Republic of",
  IRQ = "Iraq",
  ISL = "Iceland",
  ISR = "Israel",
  ITA = "Italy",
  JAM = "Jamaica",
  JEY = "Jersey",
  JOR = "Jordan",
  JPN = "Japan",
  KAZ = "Kazakhstan",
  KEN = "Kenya",
  KGZ = "Kyrgyzstan",
  KHM = "Cambodia",
  KIR = "Kiribati",
  KNA = "Saint Kitts and Nevis",
  KOR = "South Korea",
  KWT = "Kuwait",
  LAO = "Laos",
  LBN = "Lebanon",
  LBR = "Liberia",
  LBY = "Libya",
  LCA = "Saint Lucia",
  LIE = "Liechtenstein",
  LKA = "Sri Lanka",
  LSO = "Lesotho",
  LTU = "Lithuania",
  LUX = "Luxembourg",
  LVA = "Latvia",
  MAC = "Macau",
  MAF = "Collectivity of Saint Martin",
  MAR = "Morocco",
  MCO = "Monaco",
  MDA = "Moldova",
  MDG = "Madagascar",
  MDV = "Maldives",
  MEX = "Mexico",
  MHL = "Marshall Islands",
  MKD = "North Macedonia",
  MLI = "Mali",
  MLT = "Malta",
  MMR = "Myanmar",
  MNE = "Montenegro",
  MNG = "Mongolia",
  MNP = "Northern Mariana Islands",
  MOZ = "Mozambique",
  MRT = "Mauritania",
  MSR = "Montserrat",
  MTQ = "Martinique",
  MUS = "Mauritius",
  MWI = "Malawi",
  MYS = "Malaysia",
  MYT = "Mayotte",
  NAM = "Namibia",
  NCL = "New Caledonia",
  NER = "Niger",
  NFK = "Norfolk Island",
  NGA = "Nigeria",
  NIC = "Nicaragua",
  NIU = "Niue",
  NLD = "Kingdom of the Netherlands",
  NOR = "Norway",
  NPL = "Nepal",
  NRU = "Nauru",
  NZL = "New Zealand",
  OMN = "Oman",
  PAK = "Pakistan",
  PAN = "Panama",
  PCN = "Pitcairn Islands",
  PER = "Peru",
  PHL = "Philippines",
  PLW = "Palau",
  PNG = "Papua New Guinea",
  POL = "Poland",
  PRI = "Puerto Rico",
  PRK = "North Korea",
  PRT = "Portugal",
  PRY = "Paraguay",
  PSE = "State of Palestine",
  PYF = "French Polynesia",
  QAT = "Qatar",
  REU = "Réunion",
  ROU = "Romania",
  RUS = "Russia",
  RWA = "Rwanda",
  SAU = "Saudi Arabia",
  SDN = "Sudan",
  SEN = "Senegal",
  SGP = "Singapore",
  SGS = "South Georgia and the South Sandwich Islands",
  SHN = "Saint Helena, Ascension and Tristan da Cunha",
  SJM = "Svalbard and Jan Mayen",
  SLB = "Solomon Islands",
  SLE = "Sierra Leone",
  SLV = "El Salvador",
  SMR = "San Marino",
  SOM = "Somalia",
  SPM = "Saint Pierre and Miquelon",
  SRB = "Serbia",
  SSD = "South Sudan",
  STP = "São Tomé and Príncipe",
  SUR = "Suriname",
  SVK = "Slovakia",
  SVN = "Slovenia",
  SWE = "Sweden",
  SWZ = "Eswatini",
  SXM = "Sint Maarten",
  SYC = "Seychelles",
  SYR = "Syria",
  TCA = "Turks and Caicos Islands",
  TCD = "Chad",
  TGO = "Togo",
  THA = "Thailand",
  TJK = "Tajikistan",
  TKL = "Tokelau",
  TKM = "Turkmenistan",
  TLS = "East Timor",
  TON = "Tonga",
  TTO = "Trinidad and Tobago",
  TUN = "Tunisia",
  TUR = "Turkey",
  TUV = "Tuvalu",
  TWN = "Taiwan",
  TZA = "Tanzania",
  UGA = "Uganda",
  UKR = "Ukraine",
  UMI = "United States Minor Outlying Islands",
  URY = "Uruguay",
  USA = "United States",
  UZB = "Uzbekistan",
  VAT = "Vatican City",
  VCT = "Saint Vincent and the Grenadines",
  VEN = "Venezuela",
  VGB = "British Virgin Islands",
  VIR = "United States Virgin Islands",
  VNM = "Vietnam",
  VUT = "Vanuatu",
  WLF = "Wallis and Futuna",
  WSM = "Samoa",
  YEM = "Yemen",
  ZAF = "South Africa",
  ZMB = "Zambia",
  ZWE = "Zimbabwe",
}

export type ISO3CountryCode = keyof typeof CountriesByCode;
