import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "../../api/authentication";

export function useResetPassword() {
  const mutation = useMutation({
    mutationFn: ({ userId }: { userId: string }) => resetPassword({ userId }),
  });

  return mutation;
}
