import { RefundRequest } from "@paytently/types/src/RefundResponse";
import { VoidRequest } from "@paytently/types/src/VoidResponse";
import { axios } from "./fetch";
import { PaymentsSearchRequest } from "@paytently/types";

export async function searchPayments({
  pagination,
  filters,
  sorting,
}: PaymentsSearchRequest) {
  const response = await axios.post("/search-payments", {
    pagination,
    filters,
    sorting,
  });

  return response.data;
}

export async function getPaymentById(id: string) {
  const response = await axios.get(`/payments/${id}`);

  return response.data;
}

export async function refundPayment({ id, reference, amount }: RefundRequest) {
  const response = await axios.post(`/payments/${id}/refunds`, {
    reference,
    amount,
  });

  return response.data;
}

export async function voidPayment({ id, reference }: VoidRequest) {
  const response = await axios.post(`/payments/${id}/void`, {
    reference,
  });

  return response.data;
}
