import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { MRT_Column, MRT_RowData, MRT_TableInstance } from 'mantine-react-table';
import { ReactNode, useMemo, useState } from 'react';

import { ActionIcon, Menu } from '@mantine/core';

import { BottomSheet, useDisplayMode } from '@paytently/ui';

import { ReorderPanelItems } from '@#/components/DataTable/ReorderPanel/ReorderPaneItems';

interface Props<TData extends MRT_RowData> {
  table: MRT_TableInstance<TData>;
}

const MenuComponent = ({
  menuButton,
  children,
}: {
  menuButton: ReactNode;
  children: ReactNode;
}) => {
  const { isInMobileMode } = useDisplayMode();

  return isInMobileMode ? (
    <BottomSheet trigger={<BottomSheet.Trigger>{menuButton}</BottomSheet.Trigger>}>
      <BottomSheet.List>{children}</BottomSheet.List>
    </BottomSheet>
  ) : (
    <Menu closeOnItemClick={false} position="bottom-end" width={256}>
      <Menu.Target>{menuButton}</Menu.Target>
      <Menu.Dropdown variant="lg">{children}</Menu.Dropdown>
    </Menu>
  );
};

/**
 * Customised MantineReactTable column reordering panel, based on the source code:
 * https://github.com/KevinVandy/mantine-react-table/blob/3bfa3b0acd1b893a2c97950f92551688645bd9d1/packages/mantine-react-table/src/components/menus/MRT_ShowHideColumnsMenu.tsx
 *
 * This was neccessary to replace the default drag-and-drop solution with dnd kit, so that it can also work on mobile.
 * For the sake of simplicity a lot of optional functionality that we don't currently use has been removed. This means that some configuration
 * of the MRT instance won't affect this component as expected. If that functionality becomes required again down the line, refer
 * to the source code for how to reimplement it.
 */
export function ReorderPanel<TData extends MRT_RowData>({ table }: Props<TData>) {
  const {
    getAllColumns,
    getCenterLeafColumns,
    getLeftLeafColumns,
    getRightLeafColumns,
    getState,
    setColumnOrder,
  } = table;
  const { columnOrder, columnPinning } = getState();

  const allColumns = useMemo(() => {
    const columns = getAllColumns();
    if (columnOrder.length > 0 && !columns.some((col) => col.columnDef.columnDefType === 'group')) {
      return [
        ...getLeftLeafColumns(),
        ...Array.from(new Set(columnOrder)).map((colId) =>
          getCenterLeafColumns().find((col) => col?.id === colId)
        ),
        ...getRightLeafColumns(),
      ].filter(Boolean);
    }
    return columns;
  }, [
    columnOrder,
    columnPinning,
    getAllColumns(),
    getCenterLeafColumns(),
    getLeftLeafColumns(),
    getRightLeafColumns(),
  ]) as MRT_Column<TData>[];

  const [hoveredColumn, setHoveredColumn] = useState<MRT_Column<TData> | null>(null);

  const {
    icons: { IconColumns },
  } = table.options;

  const onDragEnd = (e: DragEndEvent) => {
    setHoveredColumn(null);

    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = allColumns.findIndex((column) => column.id === e.active.id);
      const newIdx = allColumns.findIndex((column) => column.id === e.over!.id);

      setColumnOrder(arrayMove(columnOrder, oldIdx, newIdx));
    }
  };

  const menuButton = (
    <ActionIcon color="gray" size="lg" variant="subtle">
      <IconColumns />
    </ActionIcon>
  );

  return (
    <MenuComponent menuButton={menuButton}>
      <DndContext
        onDragEnd={onDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={allColumns}>
          {allColumns.map((column, index) => (
            <ReorderPanelItems
              allColumns={allColumns}
              column={column}
              hoveredColumn={hoveredColumn}
              key={`${index}-${column.id}`}
              setHoveredColumn={setHoveredColumn}
              table={table}
            />
          ))}
        </SortableContext>
      </DndContext>
    </MenuComponent>
  );
}
