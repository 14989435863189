import { EmptyState } from './EmptyState/EmptyState';
import classNames from 'classnames';
import {
  MRT_Icons,
  MRT_Row,
  MRT_TableInstance,
  MRT_TableState,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { ReactNode, useState } from 'react';

import { ActionIcon, Menu, Tooltip } from '@mantine/core';

import { SearchInput } from '@paytently/ui';

import { ReorderPanel } from '@#/components/DataTable/ReorderPanel/ReorderPanel';

import ArrowDown from '@material-symbols/svg-400/rounded/arrow_downward.svg?react';
import ArrowUp from '@material-symbols/svg-400/rounded/arrow_upward.svg?react';
import ExpandAll from '@material-symbols/svg-400/rounded/expand_all.svg?react';
import MoreVert from '@material-symbols/svg-400/rounded/more_vert.svg?react';
import Tune from '@material-symbols/svg-400/rounded/tune.svg?react';

import styles from './DataTable.module.css';

export type WithOptionalPlaceholders<T> = T & { placeholder?: boolean };

export type DataTableProps<T extends object> = {
  data: T[];
  columns: MRT_ColumnDef<WithOptionalPlaceholders<T>>[];
  tableBodyRef?: React.MutableRefObject<HTMLTableSectionElement | null>;
  initialState?: Partial<MRT_TableState<WithOptionalPlaceholders<T>>>;
  onRowClick?: (row: MRT_Row<T>) => void;
  renderRowMenu: ({ row, table }: { row: MRT_Row<T>; table: MRT_TableInstance<T> }) => ReactNode;
  isLoading?: boolean;
  searchEnabled?: boolean;
};

export function DataTable<T extends object>({
  data,
  columns,
  tableBodyRef,
  isLoading,
  initialState,
  onRowClick,
  renderRowMenu,
  searchEnabled,
}: DataTableProps<T>) {
  const customIcons: Partial<MRT_Icons> = {
    IconArrowsSort: ExpandAll,
    IconSortAscending: ArrowUp,
    IconSortDescending: ArrowDown,
    IconColumns: Tune,
  };

  const [searchString, setSearchString] = useState('');

  const dataWithOptionalPlaceholders = isLoading
    ? ([...data, ...new Array(50).fill({ placeholder: true })] as WithOptionalPlaceholders<T>[])
    : (data as WithOptionalPlaceholders<T>[]);

  const tableInstance = useMantineReactTable({
    columns,
    layoutMode: 'grid',
    data: dataWithOptionalPlaceholders,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableColumnActions: false,
    enableColumnPinning: true,

    initialState: {
      columnPinning: {
        right: ['mrt-row-actions'],
      },
      pagination: { pageIndex: 0, pageSize: 50 },
      ...initialState,
    },
    state: {
      globalFilter: searchString,
    },
    // Row actions
    enableRowActions: true,
    renderRowActions: ({ row, table }) => {
      const {
        options: { localization, renderRowActionMenuItems },
      } = table;

      const isPlaceholder = row.original.placeholder;

      return isPlaceholder ? null : (
        <Menu closeOnItemClick position={'bottom-end'} withinPortal>
          <Tooltip label={localization.rowActions} openDelay={1000} withinPortal>
            <Menu.Target>
              <ActionIcon
                aria-label={localization.rowActions}
                color="gray"
                onClick={(event) => event.stopPropagation()}
                size="sm"
                variant="subtle"
              >
                <MoreVert />
              </ActionIcon>
            </Menu.Target>
          </Tooltip>

          <Menu.Dropdown onClick={(event) => event.stopPropagation()}>
            {renderRowActionMenuItems?.({
              row,
              table,
            })}
          </Menu.Dropdown>
        </Menu>
      );
    },
    renderRowActionMenuItems: renderRowMenu,

    displayColumnDefOptions: {
      'mrt-row-actions': {
        visibleInShowHideMenu: false,
        size: 60,
        grow: false,
        Header: ReorderPanel,
        mantineTableBodyCellProps: {
          className: styles.actionColumnBodyCell,
        },
        mantineTableHeadCellProps: {
          className: styles.actionColumnHeadCell,
        },
      },
    },
    positionActionsColumn: 'last',

    // Styling
    icons: customIcons,

    mantineTableHeadRowProps: {
      py: 0,
      className: styles['head-row'],
    },
    mantineTableBodyProps: { ref: tableBodyRef },
    mantineTableHeadCellProps: { className: styles['head-cells'] },
    mantineTableBodyCellProps: { className: styles['body-cells'] },
    mantineTableBodyRowProps: ({ row }: { row: any }) => ({
      onClick: () => onRowClick && onRowClick(row),
      h: '72px',
      disabled: row.original.placeholder,
      className: classNames(styles['body-row'], {
        [styles['placeholder-row']]: row.original.placeholder,
        [styles['no-click']]: !onRowClick,
      }),
    }),
    enableStickyHeader: true,
    enableGlobalFilter: true,
    mantinePaperProps: {
      className: styles.root,
      withBorder: false,
      shadow: 'none',
      radius: 'xxl',
    },
    mantineTableProps: {
      verticalSpacing: '0',
      striped: true,
      withRowBorders: true,
      highlightOnHover: !!onRowClick,
    },

    renderEmptyRowsFallback: () => <></>,
  });

  return (
    <>
      {searchEnabled && (
        <SearchInput onSearch={({ option, value }) => setSearchString(value)} showOptions={false} />
      )}
      <MantineReactTable table={tableInstance} />
      {data.length == 0 && <EmptyState />}
    </>
  );
}
