import { Section } from './TransactionDetailDataPoints';
import { TransactionDetailSection } from './TransactionDetailSection';

import { Accordion, Group } from '@mantine/core';

interface SingleColumnLayoutProps {
  data: any;
  dataPoints: Section[];
}

interface DualColumnLayoutProps {
  data: any;
  dataPoints: Section[];
  defaultOpenValues: string[];
}
export function TransactionDetailSingleColumnLayout({ data, dataPoints }: SingleColumnLayoutProps) {
  return dataPoints.map((section) => (
    <TransactionDetailSection data={data} section={section} key={section.id} />
  ));
}

export function TransactionDetailDualColumnLayout({
  data,
  dataPoints,
  defaultOpenValues,
}: DualColumnLayoutProps) {
  const columnOneDataPoints = dataPoints
    .filter(({ order }) => order === 1)
    .map((section) => <TransactionDetailSection section={section} data={data} key={section.id} />);

  const columnTwoDataPoints = dataPoints
    .filter(({ order }) => order === 2)
    .map((section) => <TransactionDetailSection section={section} data={data} key={section.id} />);

  return (
    <Group w="100%" grow align="flex-start" gap="xl">
      <Accordion multiple defaultValue={defaultOpenValues}>
        {columnOneDataPoints}
      </Accordion>
      <Accordion multiple defaultValue={defaultOpenValues}>
        {columnTwoDataPoints}
      </Accordion>
    </Group>
  );
}
