import { Link, Outlet } from '@tanstack/react-router';
import classNames from 'classnames';
import { useEffect } from 'react';

import { AppShell, Button, Stack, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { GlobalBanner, Navbar, useDisplayMode } from '@paytently/ui';
import LogoIcon from '@paytently/ui/src/assets/logos/LogoIconLight.svg?react';
import LogoFull from '@paytently/ui/src/assets/logos/LogoLight.svg?react';

import { Header, Navigation } from '@#/components';
import { useSession } from '@#/contexts/SessionContext';
import { useIsPaymentsQuickViewRoute } from '@#/hooks';

import styles from './MainLayout.module.css';

const TestModeBanner = ({
  inTestMode,
  exitTestMode,
}: {
  inTestMode: boolean;
  exitTestMode: () => void;
}) => {
  return (
    <Transition transition="slide-down" duration={400} timingFunction="ease" mounted={inTestMode}>
      {(transitionStyles) => (
        <GlobalBanner showCloseButton={false} style={transitionStyles}>
          You are currently in test mode.
          <Button
            py="xxs"
            fz="14px"
            px="sm"
            variant="subtle"
            color="white"
            size="sm"
            onClick={exitTestMode}
          >
            Exit
          </Button>
        </GlobalBanner>
      )}
    </Transition>
  );
};

export function MainLayout() {
  const { state: sessionState, dispatch } = useSession();

  const exitTestMode = () => {
    dispatch({ type: 'setTestAccount', payload: false });
  };

  return (
    <Stack w="100%" h="100%">
      <TestModeBanner inTestMode={sessionState.testAccount} exitTestMode={exitTestMode} />
      <MainLayoutContent withBanner={sessionState.testAccount} />
    </Stack>
  );
}

export function MainLayoutContent({ withBanner }: { withBanner: boolean }) {
  const { isInTabletMode, isInDesktopMode, isInMobileMode } = useDisplayMode();
  // Only default to open if viewing on desktop
  const [opened, { toggle, close }] = useDisclosure(isInDesktopMode);

  // If moving from desktop size to a smaller display mode, collapse the navbar
  useEffect(() => {
    if (!isInDesktopMode) {
      close();
    }
  }, [isInDesktopMode]);

  const isPaymentsQuickViewRoute = useIsPaymentsQuickViewRoute();

  const topOffset = withBanner ? 44 : 0;
  const navbarWidth = opened ? 280 : 92;

  return (
    <AppShell
      withBorder={true}
      padding={10}
      header={{
        height: 56,
      }}
      navbar={{
        width: navbarWidth,
        breakpoint: 'sm',
        // On mobile, fully collapse the sidebar if it isn't opened
        collapsed: { mobile: !opened },
      }}
      classNames={{
        root: classNames(styles.appShell, {
          [styles.withBanner]: withBanner,
        }),
        main: classNames(styles.main, {
          [styles.overlayModeMain]: isInTabletMode,
        }),
        navbar: classNames(styles.navBar, {
          [styles.mobileModeNavbar]: isInMobileMode,
        }),
        header: classNames(styles.header, {
          [styles.mobileModeHeader]: isInMobileMode,
        }),
      }}
      {...(isPaymentsQuickViewRoute && {
        aside: {
          width: '400px',
          breakpoint: 'sm',
          collapsed: { mobile: true },
        },
      })}
    >
      <Header isInDesktopOrTabletMode={isInDesktopMode || isInTabletMode} topOffset={topOffset} />
      {!isInMobileMode && (
        <Navbar
          opened={opened}
          onToggleCollapsed={toggle}
          overlayMode={isInTabletMode}
          topOffset={topOffset}
          links={<Navigation opened={opened} />}
          logo={
            <Link to="/">
              <div className={styles.logo}>{opened ? <LogoFull /> : <LogoIcon />}</div>
            </Link>
          }
        />
      )}

      <AppShell.Main top={topOffset}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
