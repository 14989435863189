import { TransactionDetailDataPoints } from './TransactionDetailDataPoints';
import {
  TransactionDetailDualColumnLayout,
  TransactionDetailSingleColumnLayout,
} from './TransactionDetailsSectionLayouts';
import { useMemo } from 'react';

import { Accordion } from '@mantine/core';

import { useDisplayMode } from '@paytently/ui';

import { useIsPaymentsQuickViewRoute } from '@#/hooks';

interface Props {
  readonly data: any; // TODO: use individual payment type
}

export function TransactionDetail({ data }: Props) {
  const { isInMobileMode } = useDisplayMode();
  const isPaymentsQuickView = useIsPaymentsQuickViewRoute();

  const dataPoints = TransactionDetailDataPoints;

  const defaultOpenSections = useMemo(
    () => dataPoints.reduce((accumulator, { id }) => [...accumulator, id], [] as string[]),
    TransactionDetailDataPoints
  );

  if (isInMobileMode || isPaymentsQuickView) {
    return (
      <Accordion multiple defaultValue={defaultOpenSections}>
        <TransactionDetailSingleColumnLayout data={data} dataPoints={dataPoints} />
      </Accordion>
    );
  }

  return (
    <TransactionDetailDualColumnLayout
      data={data}
      dataPoints={dataPoints}
      defaultOpenValues={defaultOpenSections}
    />
  );
}
