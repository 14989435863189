import { RenderChildrenOrPlaceHolder } from './RenderChildrenOrPlaceHolder';
import { MRT_Cell, MRT_Row, MRT_RowData } from 'mantine-react-table';

import { Status, StatusType } from '@paytently/types';

export function AmountDataTableCell<T extends MRT_RowData>({
  cell,
  row,
}: {
  cell: MRT_Cell<T>;
  row: MRT_Row<T>;
}) {
  // Get the status of this row
  const status = row
    .getAllCells()
    .find((rowCell) => rowCell.column.id === 'status')
    ?.getValue() as StatusType;

  // If one of the "failed" types, strikethrough the amount
  const failedTypes: StatusType[] = [
    Status.abandoned,
    Status.declined,
    Status.failed,
    Status.voided,
  ];
  const strikethrough = failedTypes.includes(status);

  const amount = cell.getValue<string>();
  const text = strikethrough ? <s>{amount}</s> : <span>{amount}</span>;

  return <RenderChildrenOrPlaceHolder cell={cell}>{text}</RenderChildrenOrPlaceHolder>;
}
