import classNames from 'classnames';
import { ReactNode } from 'react';

import {
  Title as MantineTitle,
  TitleProps,
  Text as MantineText,
  TextProps,
  Anchor as MantineAnchor,
} from '@mantine/core';

import styles from './Typography.module.css';

export type FontWeight = 'bold' | 'medium' | 'normal';

export const fontWeightMapping: Record<FontWeight, number> = {
  bold: 600,
  medium: 500,
  normal: 400,
};

export const Title = ({ children, weight, ...rest }: TitleProps & { weight?: FontWeight }) => (
  <MantineTitle
    className={styles.title}
    fw={weight ? fontWeightMapping[weight] : undefined}
    {...rest}
  >
    {children}
  </MantineTitle>
);

export const Text = ({
  children,
  weight,
  ...rest
}: TextProps & { children: ReactNode; weight?: FontWeight }) => (
  <MantineText fw={weight ? fontWeightMapping[weight] : undefined} {...rest}>
    {children}
  </MantineText>
);

export const LabelText = ({
  children,
  weight,
  ...rest
}: TextProps & { children: ReactNode; weight?: FontWeight }) => (
  <Text size="sm" weight={weight} {...rest}>
    {children}
  </Text>
);

export const HintText = ({
  children,
  weight,
  ...rest
}: TextProps & { children: ReactNode; weight?: FontWeight }) => (
  <Text size="xs" weight={weight} {...rest}>
    {children}
  </Text>
);

export const Anchor = ({
  children,
  weight,
  ...rest
}: TextProps & { children: ReactNode; weight?: FontWeight }) => (
  <MantineAnchor fw={weight ? fontWeightMapping[weight] : undefined} {...rest}>
    {children}
  </MantineAnchor>
);

export const DisplayText = ({
  children,
  weight,
  size = 'md',
  ...rest
}: TitleProps & { size?: 'lg' | 'md' | 'sm'; weight?: FontWeight }) => (
  <Title
    order={4}
    tt="capitalize"
    weight="bold"
    className={classNames(styles.display, styles[size])}
    {...rest}
  >
    {children}
  </Title>
);
