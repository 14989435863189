import { ReactNode } from 'react';

import { Flex, TextProps } from '@mantine/core';

import { StatusType } from '@paytently/types';
import { Text, FontWeight } from '@paytently/ui';

import { TransactionStatusIcon } from '../TransactionStatusIcon';

import styles from './PaymentStatus.module.css';

export const iconSizes = {
  sm: 16,
  lg: 24,
} as const;

export type IconSize = keyof typeof iconSizes;

interface Props {
  status: StatusType;
  iconOnly: boolean;
  iconSize: IconSize;
  subTitle?: ReactNode;
  oneLine?: boolean;
  textProps?: TextProps & { weight?: FontWeight };
}
function PaymentStatus({ status, iconOnly, iconSize, subTitle, oneLine, textProps = {} }: Props) {
  return (
    <Flex gap={iconSize === 'lg' ? 'xs' : 'xxs'} className={styles.paymentStatus}>
      <Flex
        align={iconOnly || oneLine ? 'center' : ' '}
        justify="flex-start"
        direction={iconOnly ? 'column' : 'row'}
        flex={oneLine ? '0 1 0' : '1 1 100%'}
      >
        <TransactionStatusIcon status={status} size={iconSizes[iconSize]} />
        {iconOnly && subTitle}
      </Flex>

      {!iconOnly && (
        <Flex
          justify="flex-start"
          direction={oneLine ? 'row' : 'column'}
          gap={oneLine ? 'xxs' : ''}
          flex={oneLine ? '1 1 0' : '1 1 100%'}
        >
          <Text {...textProps}>{status}</Text>
          {subTitle}
        </Flex>
      )}
    </Flex>
  );
}

export type PaymentStatusVariantProps = {
  status: StatusType;
  subTitle?: ReactNode;
};

export type PaymentTableStatusProps = {
  iconOnly: boolean;
} & PaymentStatusVariantProps;

export function TablePaymentStatus({ status, subTitle, iconOnly }: PaymentTableStatusProps) {
  return (
    <PaymentStatus
      status={status}
      iconOnly={iconOnly}
      iconSize="lg"
      subTitle={subTitle}
      textProps={{
        fz: 14,
        lh: '22px',
        weight: 'normal',
      }}
    />
  );
}

export function MetricsPaymentStatus({ status, subTitle }: PaymentStatusVariantProps) {
  return (
    <PaymentStatus
      status={status}
      iconOnly={false}
      iconSize="sm"
      subTitle={subTitle}
      textProps={{
        fz: 12,
        lh: '18px',
        weight: 'medium',
      }}
    />
  );
}

export function DetailsPaymentStatus({ status, subTitle }: PaymentStatusVariantProps) {
  return (
    <PaymentStatus
      status={status}
      iconOnly={false}
      iconSize="sm"
      subTitle={subTitle}
      oneLine
      textProps={{
        fz: 14,
        lh: '22px',
        weight: 'normal',
      }}
    />
  );
}
