import { AddOrEditUserForm, formSchema } from './AddOrEditUserForm';
import axios from 'axios';
import { zodResolver } from 'mantine-form-zod-resolver';

import { Button, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';

import { useAddNewUser } from '@paytently/api';
import { ModalActions, SnackbarBody, Title } from '@paytently/ui';

export function CreateUserModal({
  context,
  id,
  innerProps,
}: ContextModalProps<{ title: string; entityId: string }>) {
  const onClose = () => context.closeModal(id);
  const addNewUser = useAddNewUser();

  const addOrEditUserForm = useForm({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email_address: '',
    },
    validate: zodResolver(formSchema),
  });

  const handleAddUser = async (values: typeof addOrEditUserForm.values) => {
    try {
      await addNewUser.mutateAsync({
        user: { ...values, entity_id: innerProps.entityId },
      });

      modals.closeAll();

      notifications.show({
        message: (
          <SnackbarBody
            message={
              'The user has been added. Please ask them to login into the portal and setup their password.'
            }
          />
        ),
        position: 'bottom-center',
      });
    } catch (error) {
      notifications.clean();

      // TODO: Create a generic error handler for network request errors
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.Errors) {
          const responseErrors = error.response?.data?.Errors;
          const isDuplicateEmailError = responseErrors.filter(
            (responseError) => responseError.Code === 'user_already_exists'
          );

          if (isDuplicateEmailError) {
            return addOrEditUserForm.setFieldError(
              'email_address',
              'This email belongs to an active user'
            );
          }
        }
      }

      modals.closeAll();

      modals.openContextModal({
        modal: 'error',
        innerProps: {
          title: 'Error in adding the user',
          text: 'There was an unexpected error in adding the user. We apologise for the inconvenience. Please contact your customer support manager.',
        },
      });
    }
  };

  return (
    <>
      <Stack gap="lg" pb="lg">
        <Title order={2} weight="medium">
          {innerProps.title}
        </Title>

        <AddOrEditUserForm form={addOrEditUserForm} onSubmit={handleAddUser} />
      </Stack>

      <ModalActions>
        <Button onClick={onClose} size="md" variant="subtle" color="neutral.3">
          Cancel
        </Button>
        <Button
          disabled={!addOrEditUserForm.isValid()}
          form="add-or-edit-user-form"
          type="submit"
          size="md"
        >
          Add user
        </Button>
      </ModalActions>
    </>
  );
}
