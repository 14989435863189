import { Button, Flex } from '@mantine/core';

import { Chip } from '@paytently/ui';

import { AppliedFilters, FilterTypes } from '@#/components/Filters/FilterTypes';

export function AppliedFiltersDisplay({
  appliedFilters,
  setAppliedFilters,
}: {
  appliedFilters: AppliedFilters;
  setAppliedFilters: (filters: AppliedFilters) => void;
}) {
  const removeFilter = ([label]: any) => {
    const appliedFiltersClone = { ...appliedFilters };
    delete appliedFiltersClone[label];
    setAppliedFilters(appliedFiltersClone);
  };

  const removeIndividualFilter = ([label, value]: any, index: number) => {
    const currentAppliedFilterValue = appliedFilters[label].value;

    // Remove just this one value from the applied filter
    if (Array.isArray(currentAppliedFilterValue) && currentAppliedFilterValue.length > 1) {
      const newFilterValue = [
        ...currentAppliedFilterValue.slice(0, index),
        ...currentAppliedFilterValue.slice(index + 1),
      ];

      setAppliedFilters({
        ...appliedFilters,
        [label]: { ...appliedFilters[label], value: newFilterValue },
      });
    } else {
      removeFilter([label, value]);
    }
  };

  const appliedFilterEntries = Object.entries(appliedFilters);
  return (
    <Flex gap="xs" wrap="wrap">
      {appliedFilterEntries.map((filter) => {
        const [label, data] = filter;

        switch (data.type) {
          case FilterTypes.range: {
            return (
              <Chip key={label} onDelete={() => removeFilter(filter)}>
                {data.value.min} - {data.value.max}
              </Chip>
            );
          }

          case FilterTypes.multiSelect:
          case FilterTypes.schemeSelection: {
            return data.value.map((val, index) => (
              <Chip
                key={`${label}-${val.value}`}
                onDelete={() => removeIndividualFilter(filter, index)}
              >
                {val.label}
              </Chip>
            ));
          }

          case FilterTypes.singleSelect:
          case FilterTypes.chips: {
            return (
              <Chip key={label} onDelete={() => removeFilter(filter)}>
                {data.value}
              </Chip>
            );
          }

          case FilterTypes.boolean: {
            return (
              <Chip key={label} onDelete={() => removeFilter(filter)}>
                {label}
              </Chip>
            );
          }

          case FilterTypes.input: {
            return (
              <Chip key={label} onDelete={() => removeFilter(filter)}>
                {label}: {data.value}
              </Chip>
            );
          }
        }
      })}
      {appliedFilterEntries.length > 0 && (
        <Button variant="subtle" size="sm" color="primary.2" onClick={() => setAppliedFilters({})}>
          Clear all
        </Button>
      )}
    </Flex>
  );
}
