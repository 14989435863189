import { Alert, Group, Stack } from '@mantine/core';

import { Status, StatusType } from '@paytently/types';
import { Text } from '@paytently/ui';

interface Props {
  code: string;
  message: string;
  status: 'declined' | 'failed';
}

export function RejectedAlert({ code, message, status }: Props) {
  return (
    <Alert w="100%" variant="light" color="red.0" bg="red.2" title={message}>
      <Group gap="xs">
        <Text c="neutral.3" span>
          {status === Status.declined ? 'Decline' : 'Fail'}
          {' code:'}
        </Text>
        <Text span>{code}</Text>
      </Group>
    </Alert>
  );
}
