import { useMutation } from "@tanstack/react-query";

import { AddNewUserRequest } from "@paytently/types";
import { addNewUser } from "../../api/authentication";

export function useAddNewUser() {
  const mutation = useMutation({
    mutationFn: ({ user }: { user: AddNewUserRequest }) => addNewUser({ user }),
  });

  return mutation;
}
