import { UserPermissions } from "@paytently/types";
import { adminPermission, Permissions } from "../permissions";

export function hasPermission(
  checkPermission: Permissions,
  userPermissions: UserPermissions,
) {
  const checkingPermissions = [checkPermission, adminPermission];
  return checkingPermissions.some((permission) =>
    userPermissions.includes(permission),
  );
}
