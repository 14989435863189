import { createFileRoute } from '@tanstack/react-router';

import TransactionDetails from '@#/components/TransactionDetails/TransactionDetails';

import { useDocumentTitle } from '@#/hooks';

export const Route = createFileRoute('/_authenticated/details/$paymentId')({
  component: RouteComponent,
});

function RouteComponent() {
  useDocumentTitle('Payment details');
  const { paymentId } = Route.useParams();

  return <TransactionDetails paymentId={paymentId} />;
}
