import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_login/login')({
  component: RouteComponent,
  beforeLoad({ context }) {
    if (!context.isAuthenticated) return context.login();

    throw redirect({ to: '/' });
  },
});

function RouteComponent() {
  return null;
}
