import { useMutation } from "@tanstack/react-query";
import { refundPayment } from "../../api/payments";
import { RefundRequest } from "@paytently/types/src/RefundRequest";

export function useRefundPayment() {
  const mutation = useMutation({
    mutationFn: ({ id, reference, amount }: RefundRequest) =>
      refundPayment({ id, reference, amount }),
  });

  return mutation;
}
