import { createFileRoute } from '@tanstack/react-router'

import { useDocumentTitle } from '@#/hooks'
import { UserManagement } from '@#/pages'

export const Route = createFileRoute('/_authenticated/user_management')({
  component: RouteComponent,
})

function RouteComponent() {
  useDocumentTitle('User management')
  return <UserManagement />
}
