import { UserManagementTable } from './UserManagementTable';

import { Group, Stack } from '@mantine/core';

import { useGetUserList } from '@paytently/api';
import { Permissions } from '@paytently/authentication';
import { PageTitle, TextButton, useDisplayMode } from '@paytently/ui';

import { openMobileOrDesktopModal } from '@#/components/Modals/modalHelpers';

import ManageAccounts from '@material-symbols/svg-400/rounded/manage_accounts.svg?react';
import AddUserIcon from '@material-symbols/svg-400/rounded/person_add.svg?react';

import { useAuthContext } from '@#/hooks';
import { useEditUser, useResetPassword, useSuspendUser } from '@#/pages/UserManagement/hooks';
import { RequiresPermissions } from '@#/utils/RequiresPermission';

export function UserManagement() {
  const { data } = useGetUserList('TODO');

  const { openEditUserModal } = useEditUser();
  const { openSuspendUserModal } = useSuspendUser();
  const { openResetPasswordModal } = useResetPassword();

  const { isInMobileMode } = useDisplayMode();
  const { entityId } = useAuthContext();
  const openAddUserModal = () => {
    openMobileOrDesktopModal(isInMobileMode, {
      modal: 'createUser',
      size: '512px',
      innerProps: { title: 'Add new user', entityId },
    });
  };

  return (
    <Stack gap="lg" px="xl" pt="lg">
      <Group justify="space-between">
        <PageTitle title="User Management" IconComponent={ManageAccounts} />
        <RequiresPermissions checkPermission={Permissions['usermanagement:create']}>
          <TextButton ml="auto" icon={<AddUserIcon />} onClick={openAddUserModal}>
            Add user
          </TextButton>
        </RequiresPermissions>
      </Group>

      <UserManagementTable
        data={data || []}
        isLoading={false}
        onEditUser={openEditUserModal}
        onSuspendUser={openSuspendUserModal}
        onResetPassword={openResetPasswordModal}
      />
    </Stack>
  );
}
